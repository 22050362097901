import ReactModal from 'react-modal';

import useDidMount from '@root/hooks/useDidMount';

import { normalizeStringCompound } from '@utils/string';
import { isString } from '@utils/typeGuards';

import { BrModalProps } from '@root/interfaces/components/BrModal';

import BrButton from '@components/common/BrButton';

import BrScrollShadowWrapper from '../BrScrollShadowWrapper';
import Icon from '../Icon';

import './styles.scss';

const ANIMATION_DURATION_MS = 150;

const BrModal = (props: BrModalProps) => {
  const {
    modalClassNames,
    header,
    closeBtnDataTestId,
    backButtonDataTestId,
    hasCloseButton = true, // TODO: make it false and update props
    hasBackButton,
    children,
    onBackButtonClick,
    onClose,
    hasDisabledScrollWrapper,
    ...rest
  } = props;

  useDidMount(() => {
    ReactModal.setAppElement('#root');
  });

  const handleRequestClose = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    if (onClose) {
      onClose(e);
    }
  };

  const hasHeaderContent = (isString(header) && header.length) || header;
  const headerWrapperClassNames = normalizeStringCompound(['flex items-start']);

  const headerContentClassNames = normalizeStringCompound([
    isString(header) ? 'text-headers/header-3' : '',
    hasBackButton ? 'ml-xlarge' : '',
    hasCloseButton ? 'mr-xlarge' : '',
  ]);

  const overlayClassNames =
    'fixed inset-0 flex bg-color/black/70 overflow-y-auto p-xlarge backdrop-blur-sm';

  const modalDialogClassNames = normalizeStringCompound([
    'max-w-[464px] max-h-[410px] flex flex-col p-default lg:p-xlarge space-y-middle bg-white rounded-default w-full relative outline-none m-auto',
    modalClassNames,
  ]);

  const iconClassNames = normalizeStringCompound([
    'h-default w-default rounded-full',
    hasHeaderContent ? 'translate-y-xsmall' : '',
  ]);

  const portalClassNames = 'relative z-1100';

  const modalCssStyles = {
    overlay: {
      '--animation-duration': `${ANIMATION_DURATION_MS}ms`,
    },
  } as ReactModal.Styles;

  const modalHeaderContent = (
    <div className={headerWrapperClassNames}>
      {hasBackButton && (
        <Icon
          name="chevron-back"
          onClick={onBackButtonClick}
          className={iconClassNames}
          dataTestId={backButtonDataTestId}
        />
      )}
      {header && <div className={headerContentClassNames}>{header}</div>}
    </div>
  );

  return (
    <ReactModal
      overlayClassName={overlayClassNames}
      className={modalDialogClassNames}
      htmlOpenClassName="overflow-hidden"
      portalClassName={portalClassNames}
      style={modalCssStyles}
      closeTimeoutMS={ANIMATION_DURATION_MS}
      onRequestClose={handleRequestClose}
      {...rest}
    >
      {(header || hasBackButton) && modalHeaderContent}
      {hasCloseButton && (
        <div className="absolute  right-[0px] top-[7px] lg:right-[7px] lg:top-[15px] !mt-0 z-10">
          <BrButton
            cmpStyle="circle"
            cmpType="text"
            iconName="close"
            dataTestId={closeBtnDataTestId}
            hasIconLeft
            onClick={onClose}
          />
        </div>
      )}
      {hasDisabledScrollWrapper ? (
        children
      ) : (
        <BrScrollShadowWrapper>{children}</BrScrollShadowWrapper>
      )}
    </ReactModal>
  );
};

export default BrModal;
