import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';
import useModal from '@root/hooks/useModal';

import { showToast } from '@services/toasts';

import { StepModule } from '@root/interfaces/StepModules';
import { Languages } from '@root/interfaces/Languages';

import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import BrFilter from '@components/common/BrFilter';
import { useIsMobile } from '@components/common/MediaQueryMatchers';

import BrModal from '@components/common/BrModal';
import BrDrawer from '@components/common/BrDrawer';
import BrUpsell from '@components/common/BrUpsell';

import Store from './Store';
import ImtuProductsStore from './ImtuProductsStore';

import ProductsList from './components/ProductsList';
import OffersListSkeleton from './components/OffersListSkeleton';
import FeaturedOfferCard from './components/FeaturedOfferCard';

import useImtuProductsFilter from './useImtuProductsFilter';

import { imtuProductSubTypeTextMap } from '../../constants';

interface Props extends StepModule {
  selectedProduct?: ImtuProductClass;
  recipientPhone: string;
  carrier: ImtuCarrierClass;
  locale?: Languages;
  onFinish(args: { product: ImtuProductClass }): void;
}

const UI_MESSAGES = {
  PLEASE_SELECT_PRODUCT: 'Please select a plan', // t('Please select a plan')
};

const Plans: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedProduct, recipientPhone, carrier, onFinish, locale } = props;

  const { t } = useTranslation();

  const [openedDetailsId, setOpenedDetailsId] = useState<string | undefined>(undefined);

  const store = useLocalStore(
    () =>
      new Store({
        product: selectedProduct,
      }),
  );
  const imtuProductsStore = useLocalStore(() => new ImtuProductsStore());

  const {
    showModal: openUpsellDialog,
    closeModal: closeUpsellDialog,
    isModalOpen: isUpsellDialogOpen,
  } = useModal();

  const isMobile = useIsMobile();

  const handleFinish = () => {
    if (store.product) {
      onFinish({
        product: store.product,
      });
    } else {
      showToast.warning(UI_MESSAGES.PLEASE_SELECT_PRODUCT);
    }
  };

  useDidMount(async () => {
    await imtuProductsStore.fetchImtuProducts({
      recipientPhone,
      carrierCode: carrier.code,
    });
    if (!selectedProduct) {
      if (imtuProductsStore.products?.length === 1) {
        store.setProduct(imtuProductsStore.products[0]);
        handleFinish();
      }
    }
  });

  const handleOnDetailsOpen = (id: string) => {
    setOpenedDetailsId(id);
  };

  const handleProductSelect = async (value: string) => {
    const product = imtuProductsStore.products?.find((item) => {
      return item.code === value;
    });

    if (product) {
      const hasToOpenUpsellDialog =
        !store.isFeaturedPlanRefused &&
        imtuProductsStore.featuredOffer &&
        product.code !== imtuProductsStore.featuredOffer.code;
      if (hasToOpenUpsellDialog) {
        store.setPrevProduct(product);
        openUpsellDialog();
        return undefined;
      }
      store.setProduct(product);
      handleFinish();
    }
    return undefined;
  };

  const handleOnRefuseBtnClick = () => {
    store.setIsFeaturedPlanRefused(true);
  };

  useEffect(() => {
    if (store.isFeaturedPlanRefused && store.prevProduct) {
      handleProductSelect(store.prevProduct.code);
    }
  }, [store.isFeaturedPlanRefused]);

  const { filterOptions, filterFnsPipe, handleFilterSelect } = useImtuProductsFilter(
    imtuProductsStore.products || [],
  );

  const filteredImtuProducts = filterFnsPipe(imtuProductsStore.products || []);

  const upsellContent = imtuProductsStore.featuredOffer && (
    <BrUpsell
      id={imtuProductsStore.featuredOffer.code}
      locale={locale ?? Languages.En}
      planTitle={imtuProductsStore.featuredOffer?.name}
      subType={imtuProductSubTypeTextMap[imtuProductsStore.featuredOffer.subType]}
      carrierPromoText={imtuProductsStore.featuredOffer.carrierPromo?.title.toLocaleUpperCase()}
      detailsAlertDescription={imtuProductsStore.featuredOffer.carrierPromo?.description}
      detailsDescription={imtuProductsStore.featuredOffer?.description}
      actionText={`${t('Buy')} ${imtuProductsStore.featuredOffer.senderBaseAmountText}`}
      onActionBtnClick={handleProductSelect}
      onRefuseBtnClick={handleOnRefuseBtnClick}
    />
  );

  const isFilterBlockVisible = filterOptions.length > 2;

  return (
    <>
      {imtuProductsStore.isDataReady && imtuProductsStore.featuredOffer && (
        <div className="pt-default">
          <FeaturedOfferCard
            isDetailsOpened={openedDetailsId === imtuProductsStore.featuredOffer.code}
            data={imtuProductsStore.featuredOffer}
            onClick={handleProductSelect}
            onPromoBadgeClick={handleOnDetailsOpen}
          />
        </div>
      )}

      <h5 className="text-headers/header-6 text-on-colors/on-background py-xsmall pt-default">
        {t('Available top ups for {{recipientPhone}}', {
          recipientPhone,
        })}
      </h5>
      {isFilterBlockVisible && (
        <div className="overflow-x-auto br-container-x-gutter py-default">
          <BrFilter
            options={filterOptions}
            defaultValue="all"
            shouldFallbackToDefault
            isMultiSelect
            onItemSelect={handleFilterSelect}
          />
        </div>
      )}

      <div className="flex flex-col space-y-middle">
        {imtuProductsStore.isDataReady ? (
          <>
            {filteredImtuProducts?.length ? (
              <ProductsList
                isSpecialFirstOffer={!imtuProductsStore.featuredOffer}
                data={filteredImtuProducts}
                selectedItemId={store.product?.code}
                openedDetailsId={openedDetailsId}
                onItemSelect={handleProductSelect}
                onDetailsOpen={handleOnDetailsOpen}
              />
            ) : (
              <div className="first-letter:uppercase">
                {t('no products available for this carrier')}
              </div>
            )}
          </>
        ) : (
          <OffersListSkeleton />
        )}
      </div>
      {isMobile ? (
        <BrDrawer isOpen={isUpsellDialogOpen} onClose={closeUpsellDialog}>
          {upsellContent}
        </BrDrawer>
      ) : (
        <BrModal
          isOpen={isUpsellDialogOpen}
          onClose={closeUpsellDialog}
          modalClassNames="!max-h-[9999px]"
          hasDisabledScrollWrapper
        >
          {upsellContent}
        </BrModal>
      )}
    </>
  );
};

export default observer(Plans);
