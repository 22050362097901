import { normalizeStringCompound } from '@utils/string';

import { IconNames } from '@root/interfaces/components/Icon';

import BrButton from '../BrButton';
import BrIllustration, { BrIllustrationCmpType } from '../BrIllustration';

interface Props {
  title?: string;
  label?: string;
  text: string;
  illustrationType?: BrIllustrationCmpType;
  classNames?: string;
  btnCfg?: {
    text: string;
    iconName: IconNames;
    onClick(): void;
  };
}

const BrNotFoundInfo: React.FC<Props> = (props) => {
  const { title, label, text, classNames, btnCfg, illustrationType } = props;

  return (
    <div className={normalizeStringCompound(['max-w-[327px]', classNames])}>
      <div className="space-y-small flex flex-col items-center">
        {illustrationType && <BrIllustration type={illustrationType} />}
        {title && (
          <div className="text-body/callout/default text-center">
            <div>{title}</div>
            {label && <div>{label}</div>}
          </div>
        )}
        <div className="text-body/caption/default text-support-colors/highlights text-center">
          {text}
        </div>
      </div>
      {btnCfg && (
        <BrButton
          hasIconLeft
          iconName={btnCfg.iconName}
          onClick={btnCfg.onClick}
          text={btnCfg.text}
          size="middle"
          className="m-auto mt-default"
        />
      )}
    </div>
  );
};

export default BrNotFoundInfo;
