import React from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  title: string;
  description: string;
  className?: string;
}

const AutoPromoRow: React.FunctionComponent<Props> = (props: Props) => {
  const { title, description, className } = props;

  return (
    <div
      className={normalizeStringCompound([
        'text-body/callout/default text-support-colors/highlights space-y-xxsmall',
        className,
      ])}
    >
      <div className="flex items-center space-x-small">
        <div className="flex w-full justify-between">
          <div>{title}</div>
        </div>
      </div>
      <div className="flex flex-row text-body/caption-2/default space-x-xsmall">
        <div>{description}</div>
      </div>
    </div>
  );
};

export default AutoPromoRow;
