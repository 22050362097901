import React, { ForwardedRef, forwardRef } from 'react';
import { Img } from 'react-image';
import { t } from 'i18next';
import Lottie from 'lottie-react-web';

import { getCountryImgUrlByCountryCode } from '@services/cdn';
import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import { ButtonProps } from '@root/interfaces/components/Button';
import { IconNames } from '@root/interfaces/components/Icon';
import CountryCode from '@root/interfaces/CountryCode';

import { Icon } from '@components/common';

import animation from './loading.json';

import './styles.scss';

const BASIC_CLASS = 'br-btn';

type BtnCmpType =
  | 'solid'
  | 'white'
  | 'text'
  | 'link'
  | 'black-link'
  | 'light'
  | 'yellow'
  | 'gray'
  | 'black'
  | 'red'
  | 'red-text'
  | 'purple'
  | 'promoted'
  | 'white-alpha';

export interface BrButtonProps extends Omit<ButtonProps, 'size'> {
  size?: 'xSmall' | 'small' | 'middle' | 'default';
  cmpType?: BtnCmpType;
  state?: 'default' | 'tapped' | 'disabled' | 'loading';
  cmpStyle?: 'default' | 'circle';
  hasIconRight?: boolean;
  hasIconLeft?: boolean;
  iconName?: IconNames;
  iconCountryCode?: CountryCode;
  text?: string;
  children?: React.ReactNode;
}

const BtnIcon: React.FC<{ iconName: IconNames; isCircle?: boolean }> = ({
  iconName,
  isCircle,
}) => {
  return (
    <Icon
      className="btn-icon"
      name={iconName}
      width={isCircle ? 20 : undefined}
      height={isCircle ? 20 : undefined}
    />
  );
};

const BtnCountryIcon: React.FC<{ countryCode: CountryCode }> = ({ countryCode }) => {
  return (
    <Img
      src={getCountryImgUrlByCountryCode(countryCode)}
      alt={getCountryNameByCode(countryCode)}
      width={12}
      height={12}
      className="rounded-full overflow-hidden object-cover h-middle w-middle"
    />
  );
};

const BrButton = forwardRef(
  (props: BrButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      onClick,
      shape,
      size,
      disabled,
      type = 'button',
      style,
      dataTestId,
      state,
      cmpType,
      cmpStyle,
      hasIconLeft,
      hasIconRight,
      iconName,
      iconCountryCode,
      text,
      ...rest
    } = props;

    const classNames = normalizeStringCompound([
      BASIC_CLASS,
      `${BASIC_CLASS}-${shape}`,
      `${BASIC_CLASS}-${size}`,
      state === 'default' ? undefined : `${BASIC_CLASS}-${state}`,
      `${BASIC_CLASS}-${cmpType}`,
      cmpStyle === 'default' ? undefined : `${BASIC_CLASS}-${cmpStyle}`,
      className,
    ]);

    const isLoading = state === 'loading';

    const getIcon = () => {
      if (iconName) {
        return <BtnIcon iconName={iconName} />;
      }
      if (iconCountryCode) {
        return <BtnCountryIcon countryCode={iconCountryCode} />;
      }
      return undefined;
    };

    return (
      <>
        <button
          ref={ref}
          type={type}
          disabled={disabled || isLoading}
          className={classNames}
          onClick={onClick}
          style={style}
          data-test-id={dataTestId}
          {...rest}
        >
          {isLoading && (
            <div>
              <Lottie
                options={{ animationData: animation }}
                ariaRole="img"
                height={16}
                width={16}
              />
            </div>
          )}

          {cmpStyle === 'circle' && iconName ? (
            <BtnIcon iconName={iconName} isCircle />
          ) : (
            <>
              {!isLoading && hasIconLeft && getIcon()}
              {text && <span>{isLoading ? t('Please wait...') : text}</span>}
              {children && <span>{children}</span>}
              {!isLoading && hasIconRight && getIcon()}
            </>
          )}
        </button>
      </>
    );
  },
);

BrButton.defaultProps = {
  shape: 'round',
  size: 'default',
  color: 'blue',
  type: 'button',
  cmpType: 'solid',
  state: 'default',
  cmpStyle: 'default',
};

export default BrButton;
