/* eslint-disable @typescript-eslint/indent */
import React, { FC, PropsWithChildren } from 'react';

import { Icon } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import { IconNames } from '@root/interfaces/components/Icon';

import './styles.scss';

const BASIC_CLASS = 'br-alert';

export interface BrAlertProps {
  classType?:
    | 'warning'
    | 'warning-variant'
    | 'success'
    | 'success-variant'
    | 'attention'
    | 'attention-variant'
    | 'error'
    | 'error-variant'
    | 'neutral'
    | 'promoted';
  styleType?: 'default' | 'with-header' | 'large';
  icon?: IconNames;
  text?: string;
  header?: string;
  className?: string;
}

const AlertIcon: React.FC<{ iconName: IconNames; className?: string }> = ({
  iconName,
  className,
}) => {
  return (
    <Icon
      className={normalizeStringCompound(['btn-icon text-[16px]', className])}
      name={iconName}
    />
  );
};

const BrAlert: FC<PropsWithChildren<BrAlertProps>> = ({
  children,
  classType,
  styleType,
  text,
  header,
  icon,
  className,
}) => {
  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${classType}`,
    styleType === 'default' ? undefined : `${BASIC_CLASS}-${styleType}`,
    header ? 'flex flex-col !items-start' : '',
    className,
  ]);

  return (
    <div className={classNames}>
      {header && styleType === 'with-header' && (
        <div className="flex items-center mb-small">
          {icon && <AlertIcon iconName={icon} className="mr-small" />}
          <span className="text-body/callout/default">{header}</span>
        </div>
      )}
      {!header && icon && (
        <AlertIcon
          iconName={icon}
          className={normalizeStringCompound([
            'mr-small',
            styleType === 'large' ? 'text-[28px]' : '',
          ])}
        />
      )}
      {(text || children) && (
        <span className="whitespace-normal">{text || children}</span>
      )}
    </div>
  );
};

BrAlert.defaultProps = {
  classType: 'neutral',
  styleType: 'default',
  text: '',
};

export default BrAlert;
