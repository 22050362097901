/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';
import { ProductSubtype } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { Icon } from '@components/common';
import BrPriceInfo, { ExtraField } from '@components/common/BrPriceInfo';
import BrCard from '@components/common/BrCard';
import BrOfferCard from '@components/common/BrOfferCard';
import BrButton from '@components/common/BrButton';
import BrAlert from '@components/common/BrAlert';
import BrPromoCodeForm from '@components/common/BrPromoCodeForm';
import BrDrawer from '@components/common/BrDrawer';
import BrModal from '@components/common/BrModal';

import { getCountryNameByCode } from '@services/countryList';

import { useIsMobile } from '@components/common/MediaQueryMatchers';
import useModal from '@root/hooks/useModal';

import { showBrToast } from '@services/toasts';

import delay from '@utils/delay';

import { ImtuStep } from '../../constants';

import RecipientCard from './RecipientCard';

export const planSubTypeMap: { [key in ProductSubtype]: 'Data' | 'Bundle' | 'Top Up' } = {
  'Mobile Bundle': 'Bundle', // t('Bundle')
  'Mobile Data': 'Data', // t('Data')
  'Mobile Top Up': 'Top Up', // t('Top Up')}
};

interface Props {
  countryCode: CountryCode;
  recipientPhoneNumber?: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  promoInfo?: ImtuPromoOfferDetailsClass;
  currentStepIndex?: number;
  isLoading?: boolean;
  onPromoApply?(
    promoCode: string,
  ): Promise<{
    isPromoApplied: boolean;
  }>;
  onPromoChange?(): void;
  onEditStepSelect?(selectedStep: ImtuStep): void;
}

const TOAST_APPEARANCE_TIMEOUT = 300;

const ImtuTxnInfoSection: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    countryCode,
    recipientPhoneNumber,
    carrier,
    product,
    promoInfo,
    currentStepIndex,
    isLoading,
    onPromoApply,
    onPromoChange,
    onEditStepSelect,
  } = props;

  const { t } = useTranslation();

  const {
    isModalOpen: isDialogOpen,
    closeModal: closeDialog,
    showModal: showDialog,
  } = useModal();

  const isMobile = useIsMobile();

  const handleEditStepSelect = (stepToEdit: ImtuStep) => {
    onEditStepSelect?.(stepToEdit);
  };

  const isCarrierSectionVisible =
    (recipientPhoneNumber && currentStepIndex === ImtuStep.Carrier) ||
    (currentStepIndex && currentStepIndex >= ImtuStep.Plan);
  const isProductSectionVisible = currentStepIndex === ImtuStep.Summary;
  const isPromoSectionVisible =
    currentStepIndex === ImtuStep.Summary && promoInfo?.type !== 'automatic_promo';
  const isTotalSectionVisible = currentStepIndex === ImtuStep.Summary;

  const handleRecipientEditClick = () => {
    onEditStepSelect?.(ImtuStep.Phone);
  };

  const handleCarrierEditClick = () => {
    onEditStepSelect?.(ImtuStep.Carrier);
  };

  const handleOnPromoCodeSubmit = async (code: string) => {
    if (!onPromoApply) {
      return { isPromoApplied: false };
    }
    const { isPromoApplied } = await onPromoApply(code);
    if (isPromoApplied) {
      closeDialog();
      await delay(TOAST_APPEARANCE_TIMEOUT);
      showBrToast.small(`${t('Top up promo code applied successfully!')} 🥳`);
    }
    return { isPromoApplied };
  };

  const handleOnCancelPromoClick = async () => {
    if (onPromoChange) {
      onPromoChange();
      await delay(TOAST_APPEARANCE_TIMEOUT);
      showBrToast.small(`${t('The top up promo code has been deleted')} ✅`);
    }
  };

  const promoCodeForm = (
    <BrPromoCodeForm isLoading={isLoading} onSubmit={handleOnPromoCodeSubmit} />
  );

  return (
    <>
      {isCarrierSectionVisible ? (
        <BrCard className="space-y-middle">
          <RecipientCard
            countryCode={countryCode}
            recipientCfg={{
              badgeText: recipientPhoneNumber ? t('Recipient') : undefined,
              middleText: recipientPhoneNumber || t(getCountryNameByCode(countryCode)),
              onEdit: handleRecipientEditClick,
            }}
            carrierCfg={
              carrier
                ? {
                    name: carrier.name,
                    code: carrier.code,
                    onEdit: handleCarrierEditClick,
                  }
                : undefined
            }
            phoneFormCfg={
              !recipientPhoneNumber
                ? {
                    title: t("Add recipient's phone number"),
                    onPhoneChange: () => 'test',
                  }
                : undefined
            }
          />
        </BrCard>
      ) : null}

      {isProductSectionVisible && product && (
        <BrOfferCard
          id={product.classId}
          planTypeText={t(planSubTypeMap[product.subType])}
          promoBadgeText={product.carrierPromo?.title}
          title={
            product.subType === 'Mobile Top Up'
              ? product.recipientAmountText
              : product.name
          }
          descriptionShort={
            product.benefitsCfg?.termAmountDays
              ? t(`Valid for {{number}} days`, {
                  number: product.benefitsCfg.termAmountDays,
                })
              : undefined
          }
          onEditBtnClick={() => handleEditStepSelect(ImtuStep.Plan)}
        >
          {product.carrierPromo?.description || product.description ? (
            <>
              {product.carrierPromo?.description ? (
                <BrAlert
                  header={t('Promotion details')}
                  icon="egift-solid"
                  text={product.carrierPromo?.description.toUpperCase()}
                  classType="warning-variant"
                  styleType="with-header"
                  className="mb-middle !whitespace-normal w-full"
                />
              ) : null}
              {product.description ? (
                <div className="space-y-small">
                  <div className="flex space-x-xsmall">
                    <Icon name="product-fill" width={14} height={14} />
                    <div className="text-body/callout/default">
                      {t('Product Details')}
                    </div>
                  </div>
                  <div className="whitespace-break-spaces text-body/callout/default text-on-colors/on-surface">
                    {product.description}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </BrOfferCard>
      )}

      {isTotalSectionVisible && product?.senderAmount ? (
        <BrCard
          variant={promoInfo?.type === 'automatic_promo' ? 'with-promo-sheen' : 'default'}
        >
          <BrPriceInfo
            autoPromoInfo={
              promoInfo?.type === 'automatic_promo'
                ? {
                    title: promoInfo.tagline,
                    description: promoInfo.description,
                  }
                : undefined
            }
            promoInfo={
              promoInfo?.type === 'static_promo'
                ? {
                    name: promoInfo.promoId,
                    discountText: `- ${promoInfo.discountText}`,
                    descriptionText: promoInfo.tagline,
                  }
                : undefined
            }
            extraFields={
              product?.fee !== undefined
                ? [
                    { title: t('Subtotal'), valueText: product.senderBaseAmountText },
                    { title: t('Service Fee'), valueText: product.feeText || '' },
                    promoInfo?.type === 'automatic_promo'
                      ? {
                          title: t('Discount'),
                          valueText: `- ${promoInfo.discountText}`,
                          type: 'discount',
                        }
                      : undefined,
                  ].filter((item): item is ExtraField => {
                    return Boolean(item);
                  })
                : undefined
            }
            isExpandedDefault
            totalText={
              promoInfo?.priceTotalDiscountedText || product?.senderTotalAmountText
            }
            onCancelPromoClick={handleOnCancelPromoClick}
          />

          {isPromoSectionVisible && onPromoApply && !promoInfo && (
            <>
              <div className="flex justify-center mt-small">
                <BrButton
                  hasIconLeft
                  iconName="sparkles"
                  cmpType="link"
                  cmpStyle="default"
                  size="small"
                  text={t('Top up promo code')}
                  onClick={showDialog}
                />
              </div>
            </>
          )}
        </BrCard>
      ) : null}

      {isMobile ? (
        <BrDrawer
          title={t('Top up promo code')}
          isOpen={isDialogOpen}
          onClose={closeDialog}
        >
          {promoCodeForm}
        </BrDrawer>
      ) : (
        <BrModal
          header={t('Top up promo code')}
          isOpen={isDialogOpen}
          onClose={closeDialog}
        >
          {promoCodeForm}
        </BrModal>
      )}
    </>
  );
};

export default ImtuTxnInfoSection;
