import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { BrSpinnerProps } from '@root/interfaces/components/BrSpinner';

import Loading from './components/Loading';
import ProcessingOrder from './components/ProcessingOrder';
import ProcessingOrderImtu from './components/ProcessingOrderImtu';

const BrSpinner: FC<React.PropsWithChildren<BrSpinnerProps>> = (props) => {
  const { variant = 'Loading', product, className, contactProps } = props;

  const containerClassNames = normalizeStringCompound([
    'inset-0 flex flex-col items-center justify-center fixed z-1000 bg-color/black/70 backdrop-blur-sm backdrop-filter',
    className,
  ]);

  return (
    <div className={containerClassNames}>
      {variant === 'Loading' && <Loading />}
      {variant === 'Processing order' && product === 'Calling plans' && (
        <ProcessingOrder />
      )}
      {variant === 'Processing order' && product === 'IMTU' && contactProps && (
        <ProcessingOrderImtu {...contactProps} />
      )}
    </div>
  );
};

export default BrSpinner;
