import Lottie from 'lottie-react-web';
import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import { Languages } from '@root/interfaces/Languages';

import BrButton from '../BrButton';
import BrFeaturedPlanCard from '../BrFeaturedPlanCard';

import UpsellAnimationEn from './upsell-animation-en.json';
import UpsellAnimationFr from './upsell-animation-fr.json';
import UpsellAnimationEs from './upsell-animation-es.json';

interface Props extends React.ComponentProps<typeof BrFeaturedPlanCard> {
  classNames?: string;
  onRefuseBtnClick?(): void;
  locale: Languages;
}

const upsellAnimationMap: Record<Languages, React.ReactNode> = {
  en: UpsellAnimationEn,
  es: UpsellAnimationEs,
  fr: UpsellAnimationFr,
};

const BrUpsell: React.FunctionComponent<Props> = (props) => {
  const {
    className,
    subType,
    carrierPromoText,
    id,
    planTitle,
    actionText,
    detailsDescription,
    detailsAlertDescription,
    onRefuseBtnClick,
    onActionBtnClick,
    onDetailsOpen,
    locale,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound(['space-y-default', className])}>
      <Lottie options={{ animationData: upsellAnimationMap[locale], loop: false }} />
      <BrFeaturedPlanCard
        subType={subType}
        carrierPromoText={carrierPromoText}
        id={id}
        planTitle={planTitle}
        actionText={actionText}
        detailsAlertDescription={detailsAlertDescription}
        detailsDescription={detailsDescription}
        onActionBtnClick={onActionBtnClick}
        onDetailsOpen={onDetailsOpen}
      />
      <BrButton
        className="w-full"
        onClick={onRefuseBtnClick}
        text={t('No, thanks')}
        cmpType="gray"
      />
    </div>
  );
};

export default BrUpsell;
