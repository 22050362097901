import React from 'react';

import ImtuProductClass from '@root/models/ImtuProductClass';

import ImtuProductCard from '@components/modules/Imtu/components/CardItem/ImtuProductCard';

interface Props {
  data: ImtuProductClass[];
  selectedItemId?: string;
  openedDetailsId?: string;
  onItemSelect?(id: string): void;
  onDetailsOpen?(value: string): void;
  isSpecialFirstOffer?: boolean;
}

// there must be a better place to use Modal - some kind of ProductsListWithDetails or even ProductList module
const ProductsList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    data,
    selectedItemId,
    onItemSelect,
    openedDetailsId,
    onDetailsOpen,
    isSpecialFirstOffer,
  } = props;

  const handleProductSelect = (id: string) => {
    onItemSelect?.(id);
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <ImtuProductCard
            isSpecialFirstOffer={isSpecialFirstOffer && index === 0}
            key={item.code}
            data={item}
            isActive={item.code === selectedItemId}
            isDetailsOpen={item.code === openedDetailsId}
            onClick={handleProductSelect}
            onDetailsOpen={onDetailsOpen}
          />
        );
      })}
    </>
  );
};

export default ProductsList;
