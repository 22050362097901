import { makeAutoObservable } from '@nodeModules/mobx';

import { fetchImtuCarrierPromoOffers, fetchImtuOffers } from '@services/api/v1/dtcImtuK2';

import ImtuProductClass from '@root/models/ImtuProductClass';

class ImtuProductsStore {
  products?: ImtuProductClass[];

  carrierPromoOffers?: ImtuProductClass[];

  isLoading = false;

  isInitialDataRetrieved = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchImtuProducts = async ({
    recipientPhone,
    carrierCode,
  }: {
    recipientPhone: string;
    carrierCode: string;
  }) => {
    this.isLoading = true;
    try {
      this.products = await fetchImtuOffers({
        recipientPhone,
        carrierCode,
      });
    } finally {
      this.isLoading = false;
      this.isInitialDataRetrieved = true;
    }
  };

  fetchImtuCarrierPromoOffers = async (carrierPromoId: string) => {
    this.isLoading = true;
    try {
      this.carrierPromoOffers = await fetchImtuCarrierPromoOffers(carrierPromoId);
    } finally {
      this.isLoading = false;
      this.isInitialDataRetrieved = true;
    }
  };

  get featuredOffer() {
    // TODO: check if we have more options to get featured product like from offers list
    return this.products?.reduce((maxItem: ImtuProductClass | null, currentItem) => {
      if (currentItem?.priority == null) return maxItem;

      if (maxItem?.priority == null || currentItem?.priority > maxItem?.priority) {
        return currentItem;
      }

      return maxItem;
    }, null);
  }

  get isDataReady() {
    return !this.isLoading && this.isInitialDataRetrieved;
  }
}

export default ImtuProductsStore;
