import { makeAutoObservable } from 'mobx';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuCarrierPromoClass from '@root/models/ImtuCarrierPromoClass';

import {
  ImtuTxError,
  ImtuTxQueued,
  ImtuTxSuccess,
} from '@root/interfaces/contract/dtcImtuCallingPlans/CallingPlanPurchaseDetails';
import { CallingApiErrorCode } from '@root/interfaces/contract/ImtuCallingApiError';
import { RecentTopup } from '@root/interfaces/contract/dtcImtuK2/Config';
import { DtcImtuK2Transaction } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

type TransactionData =
  | DtcImtuK2Transaction
  | RecentTopup
  | ImtuTxSuccess
  | ImtuTxError
  | ImtuTxQueued;

class ImtuTransaction {
  id = '';

  productCode = '';

  carrier?: ImtuCarrierClass;

  product?: ImtuProductClass;

  submitDate = new Date();

  recipientId = '';

  senderAmount = 0;

  senderCurrency = '';

  recipientAmount? = 0;

  recipientCurrency? = '';

  recipientCountryCode: CountryCode;

  recipientPhoneNumber: string;

  status: ImtuStatus;

  errorCode?: CallingApiErrorCode;

  paymentCardId: string;

  redirectUrl?: string;

  carrierPromos?: ImtuCarrierPromoClass[];

  repeatInfo?: DtcImtuK2Transaction['repeat_info'];

  constructor(data: TransactionData) {
    makeAutoObservable(this);

    this.id = data.txid;

    if (data.carrier && data.pricing_information) {
      this.carrier = new ImtuCarrierClass({
        code: data.carrier.code,
        image_url: data.carrier.image_url,
        display_name: data.carrier.display_name,
      });

      const pricingInfo = data.pricing_information;
      this.senderAmount = Number(pricingInfo.price.amount);
      this.senderCurrency = pricingInfo.price.currency;

      this.recipientAmount = pricingInfo.value?.amount
        ? Number(pricingInfo.value?.amount)
        : this.senderAmount;
      this.recipientCurrency = pricingInfo.value?.currency || this.senderCurrency;

      this.product = new ImtuProductClass({
        code: data.offer_id,
        countryCode: data.recipient_country,
        name: data.product_name,
        carrier: this.carrier,
        senderCurrency: data.pricing_information.base_price.currency,
        senderAmount: Number(data.pricing_information.base_price.amount),
        senderTotalAmount: Number(data.pricing_information.price.amount),
        fee: Number(data.pricing_information.fees?.amount),
        recipientAmount: Number(data.pricing_information.value?.amount),
        recipientCurrency: data.pricing_information.value?.currency,
        description: data.product_description,
        subType: data.product_subtype,
        discount: pricingInfo.discount
          ? /* eslint-disable @typescript-eslint/indent */
            {
              amount: Number(pricingInfo.discount.amount),
              currency: pricingInfo.discount.currency,
            }
          : undefined,
        /* eslint-enable @typescript-eslint/indent */
      });
    }

    if ('available_carrier_promos' in data) {
      this.carrierPromos = data.available_carrier_promos?.map((promoData) => {
        return new ImtuCarrierPromoClass({
          ...promoData,
          timeStart: promoData.start_at,
          timeEnd: promoData.end_at,
        });
      });
    }

    this.submitDate = new Date(
      'purchase_started_at' in data && data.purchase_started_at
        ? data.purchase_started_at
        : 0,
    );
    this.recipientId = data.recipient_msisdn;

    this.recipientCountryCode = data.recipient_country;
    this.recipientPhoneNumber = data.recipient_msisdn;

    this.redirectUrl =
      'redirect_url' in data && data.redirect_url ? data.redirect_url : undefined;

    this.paymentCardId = data.handle_id;

    this.status = data.purchase_status;
    this.errorCode = 'error_reason' in data ? data.error_reason : undefined;

    this.repeatInfo =
      'repeat_info' in data
        ? (data.repeat_info as DtcImtuK2Transaction['repeat_info'])
        : undefined;
  }
}

export default ImtuTransaction;
