/* eslint-disable @typescript-eslint/indent */
import { FC, Fragment, PropsWithChildren, createElement } from 'react';

import { normalizeStringCompound } from '@utils/string';

import BrBadge, { BrBadgeProps } from '../BrBadge';

import { ReactComponent as PromoSheenIcon } from './promoSheen.svg';

import './styles.scss';

const BASIC_CLASS = 'br-card';

export interface BrCardProps {
  variant?:
    | 'default'
    | 'with-image-bg'
    | 'without-paddings'
    | 'featured'
    | 'without-bg'
    | 'no-bg-paddings'
    | 'with-liner'
    | 'new-featured'
    | 'small'
    | 'with-promo-sheen';

  featuredText?: string;
  bgUrl?: string;
  className?: string;
  fallbackImageUrl?: string;
  badgeCfg?: BrBadgeProps[];
  onClick?(): void;
}

const BrCard: FC<PropsWithChildren<BrCardProps>> = ({
  children,
  featuredText,
  bgUrl,
  variant = 'default',
  className,
  fallbackImageUrl,
  badgeCfg,
  onClick,
}) => {
  const classNames = normalizeStringCompound([
    `${BASIC_CLASS} ${BASIC_CLASS}-${variant}`,
    className,
  ]);
  const style = {
    backgroundImage: [
      bgUrl && `url(${bgUrl})`,
      fallbackImageUrl && `url(${fallbackImageUrl})`,
    ]
      .filter(Boolean)
      .join(', '),
  };

  return createElement(onClick ? 'button' : Fragment, {
    onClick,
    className: 'w-full text-left',
    children: (
      <>
        {/* TODO: put rendering badgeCfg outside of BrCard */}
        {variant === 'new-featured' ? (
          <div className={normalizeStringCompound([classNames, className])}>
            <div className="br-card--image" style={style}>
              <div className="br-card--gradient">
                <div className="space-x-small flex">
                  {badgeCfg?.map(
                    (badge) =>
                      Boolean(badge.text) &&
                      createElement(badge.onClick ? 'button' : Fragment, {
                        children: <BrBadge key={badge.text} {...badge} />,
                        ...(badge.onClick
                          ? {
                              onClick: (e: Event) => {
                                e.stopPropagation();
                                badge.onClick?.();
                              },
                            }
                          : {}),
                      }),
                  )}
                </div>
              </div>
            </div>
            {featuredText && (
              <span className="br-card--featured-label">{featuredText}</span>
            )}
            {children}
          </div>
        ) : (
          <div className={normalizeStringCompound([classNames, className])} style={style}>
            {featuredText && (
              <span className="br-card--featured-label">{featuredText}</span>
            )}
            {variant === 'with-promo-sheen' && (
              <PromoSheenIcon className="top-0 left-0 absolute pointer-events-none" />
            )}
            {variant === 'with-liner' ? (
              <div className="br-card--linear-wrapper">{children}</div>
            ) : (
              children
            )}
          </div>
        )}
      </>
    ),
  });
};

export default BrCard;
