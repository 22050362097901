/* eslint-disable @typescript-eslint/indent */
import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { getNormalizedCountryDialCode } from '@components/common/PhoneInput/helpers';

import { FormConfig } from '@root/interfaces/components/Form';
import CountryCode from '@root/interfaces/CountryCode';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

import {
  getPhoneDialCodeByCountryCode,
  getPhoneInfoByNumber,
  isValidInternationalPhoneNumber,
} from '@helpers/phone';

import { getCountryNameByCode } from '@services/countryList';

import Yup from '@utils/validation/yup';
import { findKeyByValue } from '@utils/object';

import FormPhone from './Form';

interface Props extends TestIdDataAttr {
  phone?: string;
  countryCode: CountryCode;
  className?: string;
  onSubmit?(value: string): void;
  onChange?(params: { phoneNumber: string }): void;
  onValidation?(res: { isValid: boolean; errorReason?: ValidationError }): void;
}

export type ValidationError =
  | 'common'
  | 'invalidDialCode'
  | 'invalidCountry'
  | 'empty'
  | 'invalidPhone';

const CLEARED_VALUE = '';
const MASK_PREFIX = '+';

const PhoneForm = forwardRef((props: Props, ref) => {
  const {
    phone,
    countryCode,
    dataTestId,
    onSubmit,
    onChange,
    className,
    onValidation,
  } = props;

  const { t } = useTranslation();

  const initialValues = {
    phone: phone || `${getPhoneDialCodeByCountryCode(countryCode)}`,
  };

  const countryName = t(getCountryNameByCode(countryCode));
  const phoneCode = t(getNormalizedCountryDialCode(countryCode));

  const validationMsgMap: Record<ValidationError, string> = useMemo(() => {
    return {
      common: t('The number that you entered does not look like a valid phone number'),
      invalidDialCode: t(
        'That phone code is invalid. For {{countryName}}, please use {{phoneCode}}.',
        {
          countryName,
          phoneCode,
        },
      ),
      invalidCountry: t('Wrong country number'),
      empty: t('Invalid phone number'),
      invalidPhone: t('Invalid phone number'),
    };
  }, [t, countryName, phoneCode]);

  const formInitialProps: FormConfig<{ phone: string }> = useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validationSchema: Yup.object().shape({
        phone: Yup.string()
          .ensure()
          .label('phone')
          .test('is empty', t(validationMsgMap.empty), (value: string) => {
            const isValid = value !== CLEARED_VALUE && value !== MASK_PREFIX;
            return isValid;
          })
          .test(
            'is valid dial code',
            t(validationMsgMap.invalidDialCode, {
              countryName,
              phoneCode,
            }),
            (value: string) => {
              const isValid = value.startsWith(phoneCode);
              return isValid;
            },
          )
          .test(
            'is valid phone number',
            t(validationMsgMap.invalidPhone),
            (value: string, ctx) => {
              const isValid = Boolean(value && isValidInternationalPhoneNumber(value));
              return isValid;
            },
          )
          .test(
            'does phone belongs to country',
            t(validationMsgMap.invalidCountry),
            (value: string) => {
              const isValid = countryCode === getPhoneInfoByNumber(value).country;
              return isValid;
            },
          ),
      }),
      onSubmit: (values: { phone: string }) => {
        onSubmit?.(values.phone);
      },
      validateOnMount: true,
    }),
    [countryCode, phone],
  );

  const handleOnValidation = useCallback(
    (res: { isValid: boolean; errorText?: string }) => {
      onValidation?.({
        isValid: res.isValid,
        errorReason: findKeyByValue(validationMsgMap, res.errorText),
      });
    },
    [onValidation, validationMsgMap],
  );

  return (
    <Formik {...formInitialProps}>
      <FormPhone
        ref={ref}
        countryCode={countryCode}
        className={className}
        dataTestId={dataTestId}
        validationSchema={formInitialProps.validationSchema}
        onChange={onChange}
        onValidation={handleOnValidation}
      />
    </Formik>
  );
});

export default PhoneForm;
