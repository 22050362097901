import React, { FC, PropsWithChildren } from 'react';
import Lottie from 'lottie-react-web';
import { useTranslation } from 'react-i18next';

import BrContacts, { BrContactsProps } from '@components/common/BrContacts';

import { ReactComponent as BrSpinnerLogo } from '@src/static/assets/images/br-spinner-logo.svg';

import animation from './liquid_b.json';

const ProcessingOrderImtu: FC<PropsWithChildren<BrContactsProps>> = (props) => {
  const { t } = useTranslation();
  const { className, imageUrl, cmpType, cmpSize, countryCode, name, phoneNumber } = props;
  return (
    <>
      <div className="relative mb-xxlarge">
        <div className="flex flex-row space-x-small justify-between items-center max-w-[325px] w-full">
          <div className="bg-red-400 z-30 box-content min-w-[64px] h-[64px] rounded-full mr-small">
            <BrSpinnerLogo className="w-full h-full inline-block" />
          </div>

          <Lottie options={{ animationData: animation }} ariaRole="img" height={94} />

          <BrContacts
            className={className}
            imageUrl={imageUrl}
            cmpType={cmpType}
            cmpSize={cmpSize}
            countryCode={countryCode}
            name={name}
            phoneNumber={phoneNumber}
          />
        </div>
      </div>
      <div className="relative z-30 text-center">
        <div className="text-headers/header-3 text-on-colors/on-secondary mb-xxxlarge">
          <div>{t("We're processing your")}</div>
          {t('request')}
        </div>
        <div className="text-body/primary/default text-on-colors/on-secondary">
          {t('Please wait...')}
        </div>
      </div>
    </>
  );
};

export default ProcessingOrderImtu;
