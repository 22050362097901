import { ProductSubtype } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

export enum ImtuStep {
  Country,
  Phone,
  Carrier,
  Plan,
  Summary,
}

export const imtuProductSubTypeTextMap: Record<ProductSubtype, string> = {
  'Mobile Bundle': 'Bundle', // t('Bundle')
  'Mobile Data': 'Data', // t('Data')
  'Mobile Top Up': 'Top Up', // t('Top Up')
};

export const ERROR_MESSAGE = {
  INVALID_PROMO: 'Promo code is not valid', // t('Promo code is not valid')
};

// https://s3.amazonaws.com/idtm-public-config/content/imtu/promo_terms_en.json
// https://s3.amazonaws.com/idtm-public-config/content/imtu/promo_terms_es.json
export const promoTermsText =
  "Bonus levels, expiration dates and all other terms for carrier-specific promotions (when offered) are established and provided by the in-country mobile operator. For date-specific promotions offered by a carrier, orders must be placed within the specified time frame based on local time in the recipient's country. IDT is not responsible for the terms or delivery of these carrier-specific promotions."; // t("Bonus levels, expiration dates and all other terms for carrier-specific promotions (when offered) are established and provided by the in-country mobile operator. For date-specific promotions offered by a carrier, orders must be placed within the specified time frame based on local time in the recipient's country. IDT is not responsible for the terms or delivery of these carrier-specific promotions.");
