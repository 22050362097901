import { useLayoutEffect, useState } from 'react';
import AnimateHeightContainer from 'react-animate-height/dist/esm/index';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  expandableContainerClassNames?: string;
  animationDuration?: number;
  isExpanded?: boolean;
}

const DEFAULT_ANIMATION_DURATION = 500;

const BrExpandableContainer: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    expandableContainerClassNames,
    isExpanded,
    animationDuration = DEFAULT_ANIMATION_DURATION,
    children,
  } = props;

  const [isContainerExpanded, setIsContainerExpanded] = useState(isExpanded);

  useLayoutEffect(() => {
    if (isExpanded) {
      setIsContainerExpanded(true);
    }
  }, [isExpanded]);

  const containerHeight = isContainerExpanded && isExpanded ? 'auto' : 0;

  const handleOnHeightAnimationEnd = () => {
    if (!isExpanded) {
      // on height animation end a children component will be unmounted
      setIsContainerExpanded(false);
    }
  };

  return (
    <AnimateHeightContainer
      height={containerHeight}
      duration={animationDuration}
      onHeightAnimationEnd={handleOnHeightAnimationEnd}
    >
      <div className={normalizeStringCompound([expandableContainerClassNames])}>
        {/* children will be rendered only in case when the height animation is in progress. It is beneficial in terms of rendering only visible (not collapsed) content on the page on the application startup */}
        {isContainerExpanded && children}
      </div>
    </AnimateHeightContainer>
  );
};

export default BrExpandableContainer;
