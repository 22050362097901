import { makeAutoObservable } from 'mobx';

import { formatMoneyToText } from '@helpers/money';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuPromoV2Class from '@root/models/ImtuPromoV2Class';

import CountryCode from '@root/interfaces/CountryCode';
import { ProductSubtype } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

type OfferBenefitCfg = {
  termAmountDays?: number;
  smsAmount?: number;
  dataAmountGb?: number;
  minutesAmount?: number;
  hasUnlimitedSms?: boolean;
  hasUnlimitedMinutes?: boolean;
  hasUnlimitedData?: boolean;
};

export interface ImtuProductClassParams {
  code: string;
  name: string;
  senderCurrency: string;
  senderAmount: number;
  senderTotalAmount: number;
  carrier: ImtuCarrierClass;
  countryCode: CountryCode;
  subType: ProductSubtype;
  fee?: number;
  recipientAmount?: number;
  recipientCurrency?: string;
  denomText?: string;
  description?: string;
  carrierPromo?: ImtuPromoV2Class;
  priority?: number;
  benefitsCfg?: OfferBenefitCfg;
  discount?: {
    amount: number;
    currency: string;
  };
}

class ImtuProductClass {
  code = '-1';

  classId = '-1';

  countryCode: CountryCode;

  name = '';

  description?: string;

  carrier = new ImtuCarrierClass();

  senderAmount = 0;

  senderTotalAmount = 0;

  senderCurrency = '';

  recipientAmount = 0;

  recipientCurrency = '';

  fee = 0;

  mask = '';

  priority?: number;

  carrierPromo?: ImtuPromoV2Class;

  subType: ProductSubtype;

  denomText?: string;

  benefitsCfg?: OfferBenefitCfg;

  discount?: {
    amount: number;
    currency: string;
  };

  constructor(data: ImtuProductClassParams) {
    makeAutoObservable(this);
    this.code = data.code;
    this.countryCode = data.countryCode;
    this.name = data.name;
    this.carrier = data.carrier;
    this.senderCurrency = data.senderCurrency;

    this.senderAmount = data.senderAmount;
    this.senderTotalAmount = data.senderTotalAmount;

    this.priority = data.priority;

    this.carrierPromo = data.carrierPromo;

    this.fee = data.fee || this.fee;

    this.recipientAmount = data.recipientAmount || this.recipientAmount;
    this.recipientCurrency = data.recipientCurrency || this.recipientCurrency;
    this.denomText = data.denomText;
    this.description = data.description;
    this.subType = data.subType;

    this.benefitsCfg = data.benefitsCfg;

    this.discount = data.discount;
  }

  get hasSameSenderRecipientAmount() {
    return this.recipientAmountText === this.senderBaseAmountText;
  }

  get senderTotalAmountText() {
    return formatMoneyToText(this.senderTotalAmount, this.senderCurrency);
  }

  get recipientAmountText() {
    return formatMoneyToText(this.recipientAmount, this.recipientCurrency);
  }

  get senderBaseAmountText() {
    return formatMoneyToText(this.senderAmount, this.senderCurrency);
  }

  get feeText() {
    return formatMoneyToText(this.fee, this.senderCurrency);
  }

  get discountText() {
    if (!this.discount) {
      return null;
    }
    return formatMoneyToText(this.discount.amount, this.discount.currency);
  }
}

export default ImtuProductClass;
