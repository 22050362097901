import React, { PropsWithChildren, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IconNames } from '@root/interfaces/components/Icon';
import { Icon } from '@components/common';
import BrButton from '@components/common/BrButton';
import BrDrawer from '@components/common/BrDrawer';
import BrModal from '@components/common/BrModal';
import useModal from '@root/hooks/useModal';
import { normalizeStringCompound } from '@utils/string';
import { useIsMobile } from '@components/common/MediaQueryMatchers';

interface Props {
  title: string;
  iconName: IconNames;
  maxHeight: number;
  btnText: string;
  classNames?: string;
}

const PlanExpandableInfoBlock: React.FunctionComponent<PropsWithChildren<Props>> = (
  props,
) => {
  const { title, iconName, maxHeight, children, btnText, classNames } = props;

  const {
    isModalOpen,
    modalContent,
    showModal,
    closeModal,
    setModalContent,
  } = useModal();

  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  const [isExpandable, setIsExpandable] = useState(false);

  const checkIsExpandable = () => {
    requestAnimationFrame(() => {
      const content = contentRef.current;

      if (content) {
        content.classList.remove('line-clamp-3');
        setIsExpandable(content.scrollHeight > maxHeight);
      }
    });
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    checkIsExpandable();

    window.addEventListener('resize', checkIsExpandable);
    return () => {
      window.removeEventListener('resize', checkIsExpandable);
    };
  }, [maxHeight, children]);

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalContent(
      <div className="relative text-body/footnote/default whitespace-break-spaces overflow-hidden">
        {children}
      </div>,
    );
    showModal();
  };

  return (
    <>
      <div className="space-y-small">
        <div className="flex text-body/callout/default space-x-xsmall">
          <Icon name={iconName} />
          <span>{title}</span>
        </div>
        <div
          style={{ maxHeight }}
          className={normalizeStringCompound(['relative overflow-hidden', classNames])}
        >
          <div
            ref={contentRef}
            className={normalizeStringCompound([
              'relative text-body/footnote/default whitespace-break-spaces',
              isExpandable ? 'line-clamp-3' : '',
            ])}
          >
            {children}
          </div>
          {isExpandable && (
            <div className="absolute bottom-0 h-middle w-full bg-gradient-to-t from-white to-transparent" />
          )}
        </div>
        {isExpandable && (
          <BrButton
            cmpType="link"
            size="small"
            text={btnText}
            onClick={handleExpandClick}
          />
        )}
      </div>
      {isMobile ? (
        <BrDrawer
          title={t('Available destinations')}
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          {modalContent}
        </BrDrawer>
      ) : (
        <BrModal
          header={t('Available destinations')}
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          {modalContent}
        </BrModal>
      )}
    </>
  );
};

export default PlanExpandableInfoBlock;
