import React from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import BrCarrier from '@components/common/BrCarrier';
import BrButton from '@components/common/BrButton';

import { normalizeStringCompound } from '@utils/string';

type Size = 'Large' | 'Medium' | 'Small' | 'xSmall';

interface Props {
  size: Size;
  name: string;
  carrierCode: string;
  countryCode: CountryCode;
  classNameText?: string;
  onClick?(): void;
}

const CarrierInfo: React.FC<Props> = (props) => {
  const { size, name, carrierCode, countryCode, classNameText, onClick } = props;

  const { t } = useTranslation();

  const nameClassNames = normalizeStringCompound([
    size === 'Large' ? 'text-headers/header-4' : undefined,
    size === 'Medium' ? 'text-body/primary/default' : undefined,
    size === 'Small' ? 'text-body/caption/default' : undefined,
    size === 'xSmall' ? 'text-body/callout/default' : undefined,
    'text-on-colors/on-primary',
    classNameText,
  ]);

  const leftPartContainerClassNames = normalizeStringCompound([
    'flex flex-row items-center',
    size === 'xSmall' ? 'space-x-small' : 'space-x-middle',
  ]);

  return (
    <div className="flex items-center justify-between space-x-middle">
      <div className={leftPartContainerClassNames}>
        <BrCarrier size={size} carrierCode={carrierCode} countryCode={countryCode} />
        <div className={nameClassNames}>{name}</div>
      </div>
      {onClick ? (
        <div className="justify-between">
          <BrButton cmpType="link" text={t('Edit')} onClick={onClick} />
        </div>
      ) : null}
    </div>
  );
};

export default CarrierInfo;
