import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import BrFilter from '@components/common/BrFilter';
import BrButton from '@components/common/BrButton';
import BrCard from '@components/common/BrCard';
import BrNotFound from '@components/common/BrNotFoundInfo';

import { formatPhone } from '@helpers/phone';

import ImtuTransaction from '@root/models/ImtuTransaction';

import RecentTxn from './components/RecentTxn';

interface Props {
  txns: ImtuTransaction[];
  onItemClick(id: string): void;
  onStartTopup?(): void;
  onSeeAllClick?(): void;
}

const RecentActivitySection: React.FC<Props> = (props) => {
  const { txns, onItemClick, onStartTopup, onSeeAllClick } = props;

  const [selectedFilter, setSelectedFilter] = useState<'all' | 'subscriptions'>('all');

  const { t } = useTranslation();

  const filterOptions = [
    { value: 'all', title: t('All') },
    {
      value: 'subscriptions',
      title: t('Subscriptions'),
    },
  ];

  const handleFilterItemSelect = (values: Array<'all' | 'subscriptions'>) => {
    if (values) {
      setSelectedFilter(values[0]);
    }
  };

  const renderRecentTxnItem = (
    txn: ImtuTransaction,
    idx: number,
    txnsArr: ImtuTransaction[],
  ) => {
    return (
      txn.recipientAmount &&
      txn.recipientCurrency &&
      txn.carrier?.name && (
        <RecentTxn
          key={txn.id}
          promoText={txn.carrierPromos?.[0]?.title?.toUpperCase()}
          phone={formatPhone(txn.recipientPhoneNumber, txn.recipientCountryCode)}
          senderAmount={txn.status === 'scheduled' ? undefined : txn.senderAmount}
          senderCurrency={txn.senderCurrency}
          recipientAmount={txn.recipientAmount}
          recipientCurrency={txn.recipientCurrency}
          carrierName={txn.carrier.name}
          recipientCountryCode={txn.recipientCountryCode}
          onClickProps={
            txn.status === 'scheduled' ? undefined : { onClick: onItemClick, id: txn.id }
          }
          hasSeparateLine={txnsArr.length - 1 !== idx}
          additionalText={
            /* eslint-disable @typescript-eslint/indent */
            txn.status === 'scheduled' && txn.repeatInfo?.launch_at
              ? t('Scheduled on {{date}}', {
                  date: format(txn.repeatInfo.launch_at, 'MMM dd, yyyy'),
                })
              : undefined
            /* eslint-disable @typescript-eslint/indent */
          }
        />
      )
    );
  };

  return (
    <div className="bg-color/surface rounded-default py-default px-xlarge md:px-default">
      <div className="flex justify-between items-center">
        <div className="text-headers/header-4">{t('My top up activity')}</div>
        {onSeeAllClick && selectedFilter === 'all' && (
          <BrButton text={t('See all')} onClick={onSeeAllClick} cmpType="link" />
        )}
      </div>
      <div className="py-default">
        <BrFilter
          options={filterOptions}
          onItemSelect={handleFilterItemSelect}
          defaultValue={selectedFilter}
          color="Gray"
          shouldFallbackToDefault
        />
      </div>
      <div className="space-y-middle">
        {selectedFilter === 'all' && txns.map(renderRecentTxnItem)}

        {selectedFilter === 'subscriptions' &&
          (txns.filter((txn) => txn.status === 'scheduled').length ? (
            txns.filter((txn) => txn.status === 'scheduled').map(renderRecentTxnItem)
          ) : (
            <BrCard variant="without-paddings" className="flex justify-center">
              <BrNotFound
                classNames="py-middle"
                title={t('No subscriptions')}
                illustrationType="noSubscriptions"
                text={t(
                  'Save time on future top up with our automatic top up service, you can manage it anytime.',
                )}
                btnCfg={
                  onStartTopup
                    ? {
                        onClick: onStartTopup,
                        text: t('Start top up'),
                        iconName: 'send-top-up',
                      }
                    : undefined
                }
              />
            </BrCard>
          ))}
      </div>
    </div>
  );
};

export default RecentActivitySection;
