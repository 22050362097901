import firebase from 'firebase';
import 'firebase/analytics';

import { firebaseAuthAPI } from '@services/firebase';
import brazeAPI from '@services/braze';
import smartlookAPI from '@services/smartlook';
import kochavaApi from '@services/kochava';
import GoogleAnalyticsApi from '@services/googleAnalytics';

import UserAccountClass from '@root/models/UserAccountClass';

import {
  ActionParams,
  AnalyticsErrorEventParams,
  BackendFailureParams,
  ButtonPressEventParams,
  ImtuPurchaseMarketingServiceParams,
  PurchaseImtuOptions,
  SelectEventParams,
  SentCallingRechargeTransactionParams,
  ImtuTxnTrackingParams,
  TrackingEventParams,
  CallingTxnTrackingParams,
} from '@root/interfaces/Analytics';

import CountryCode from '@root/interfaces/CountryCode';
import { ImtuProductType } from '@root/interfaces/MobileTopUp';

import { isNumber } from '@utils/typeGuards';

import { IMTU_PRODUCT_TYPES } from '@root/constants/mobileTopUp/imtuProductTypes';

import { getGaPurchaseImtuParams } from './helpers';

import { BrazeEvent, GoogleAnalyticsEvent, PURCHASE_ITEM } from './constants';

const { app: brazeApp } = brazeAPI;

const { app: firebaseApp } = firebaseAuthAPI;

const { app: smartlookApp } = smartlookAPI;

const gtmUniversalAnalytics = new GoogleAnalyticsApi();
// it is hard to mock firebase analytics with typescript at this moment
// but the amount of error logs it produces it annoying
// so test environment have a parameter to hard block it
// @ts-ignore
const firebaseGa4Analytics =
  process.env.REACT_APP_ANALYTICS_FIREBASE_STUB === 'true'
    ? ({} as firebase.analytics.Analytics)
    : firebaseApp.analytics();

// replace all analytics functions with console log when logging is disabled
if (process.env.REACT_APP_ANALYTICS !== 'active') {
  // eslint-disable-next-line no-console
  firebaseGa4Analytics.logEvent = console.log;
  Object.keys(gtmUniversalAnalytics).forEach((key) => {
    // @ts-ignore
    if (
      key in gtmUniversalAnalytics &&
      // @ts-ignore
      typeof gtmUniversalAnalytics[key] === 'function'
    ) {
      // @ts-ignore
      // eslint-disable-next-line func-names
      gtmUniversalAnalytics[key] = function (...args) {
        // eslint-disable-next-line no-console
        console.log(`analytics ${key} stub: `, args);
      };
    }
  });
}

const setUserPropsForAnalytics = (user?: UserAccountClass) => {
  if (user) {
    firebaseGa4Analytics.setUserId(user.id);
    firebaseGa4Analytics.setUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarURL: user.avatarURL,
    });
    gtmUniversalAnalytics.setUserId(user.id);
    gtmUniversalAnalytics.setUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarURL: user.avatarURL,
    });
  } // else anonymous user
};

const setUserPropsForBraze = (user?: UserAccountClass) => {
  if (user) {
    brazeApp.changeUser(user.id);
    const currentBrazeUser = brazeApp.getUser();
    currentBrazeUser.setFirstName(user.firstName);
    currentBrazeUser.setLastName(user.lastName);
    currentBrazeUser.setEmail(user.email);
    currentBrazeUser.setAvatarImageUrl(user.avatarURL ?? '');
    currentBrazeUser.setPhoneNumber(user.phone);
    currentBrazeUser.setLanguage(user.language ?? '');
  }
};

const setUserPropsForSmartLook = (user?: UserAccountClass) => {
  // TODO: remove user.id check after figuring out what actual id we should use - DTC or BMBCustomerId
  if (user && user.id) {
    smartlookApp?.identify(user.id, {
      name: user.fullName,
      email: user.email,
    });
  }
};

const setUserPropsForKochava = (user?: UserAccountClass) => {
  if (user) {
    kochavaApi.setUserId({
      userId: user.id,
      phoneNumber: user.phone,
      email: user.email,
    });
  }
};

// TODO: setUserPropForIntercom

export const setupUser = (user?: UserAccountClass) => {
  setUserPropsForAnalytics(user);
  setUserPropsForBraze(user);
  setUserPropsForSmartLook(user);
  setUserPropsForKochava(user);
  // setUserPropsForIntercom(user);
};

const logoutBrazeUser = (userId: string) => {
  brazeApp.changeUser(`logout-${userId}`);
};

export const trackGoogleAnalyticsEvent = (
  name: GoogleAnalyticsEvent,
  params?: TrackingEventParams,
) => {
  firebaseGa4Analytics.logEvent(name as string, params);
  gtmUniversalAnalytics.logEvent(name as string, params);
};

export const trackGaMtModalAppLinkClickEvent = (params?: TrackingEventParams) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.MtModalAppLinkClick, params);
};

export const trackPageView = (location: string) => {
  gtmUniversalAnalytics.trackPageView(location);
  // BRP-1637 - disabled for investigation of the PageView event duplication
  // firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.PageView, {
  //   firebase_screen: location,
  // });
};

export const trackBrazeEvent = (eventName: string, params?: TrackingEventParams) => {
  brazeApp.logCustomEvent(eventName, params);
};

// TODO: trackIntercomEvent(eventName, params)

export const trackCustomEvent = (
  eventName: GoogleAnalyticsEvent,
  eventParams?: TrackingEventParams,
) => {
  trackGoogleAnalyticsEvent(eventName, eventParams);
  trackBrazeEvent(eventName, eventParams);
  // TODO: trackIntercomEvent(eventName, eventParams);
};

export const trackButtonPress = (eventParams?: ButtonPressEventParams) => {
  trackCustomEvent(GoogleAnalyticsEvent.ButtonPress, eventParams);
  if (eventParams?.button_name) {
    trackBrazeEvent(
      `${GoogleAnalyticsEvent.ButtonPress}_${eventParams.button_name}`,
      eventParams,
    );
  }
};

export const trackSelect = (params?: SelectEventParams) => {
  const selectEventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Select, selectEventParams);
};

export const trackBrazePurchaseEvent = (
  product: string,
  amount: number,
  params: Dictionary<undefined | string | number | object>,
) => {
  const currency = PURCHASE_ITEM.CURRENCY;
  brazeApp.logPurchase(product, amount, currency, PURCHASE_ITEM.QUANTITY, {
    delivery_method: product,
    ...params,
  });
};

export const trackBrazeImtuPurchaseEvent = (
  type: string,
  recipientNumber: string,
  recipientCountry?: CountryCode,
) => {
  trackBrazeEvent(`${type}_${BrazeEvent.PurchaseImtu}`, {
    recipientNumber,
    recipientCountry,
  });
};

export const trackBrazeCustomAttribute = (
  attribute: string,
  value: string | number,
  shouldIncrementAttribute?: boolean,
) => {
  const currentBrazeUser = brazeApp.getUser();
  if (shouldIncrementAttribute && isNumber(value)) {
    currentBrazeUser.incrementCustomUserAttribute(attribute, value);
  } else {
    currentBrazeUser.addToCustomAttributeArray(attribute, String(value));
  }
};

export const trackGaPurchasePinless = ({
  userCountryCode,
}: {
  userCountryCode: CountryCode;
}) => {
  // No params needed so far
  const trackingParams = {
    userCountryCode,
  };
  firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.PurchasePinless, trackingParams);
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchasePinless,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    trackingParams,
  );
};

export const trackGaPurchaseImtu = ({
  transactionId,
  item,
  amount,
  userCountryCode,
  options,
}: {
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  userCountryCode: CountryCode;
  options: PurchaseImtuOptions;
}) => {
  const trackingParams = getGaPurchaseImtuParams({
    transactionId,
    item,
    amount,
    userCountryCode,
    options,
  });
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseImtu as string,
    trackingParams,
  );
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseImtu,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    trackingParams,
  );
};

export const trackImtuPurchaseToMarketingServices = (
  params: ImtuPurchaseMarketingServiceParams,
) => {
  const {
    amount,
    transactionId,
    item: {
      params: { phone, type, country, carrier, plan },
    },
  } = params;

  trackBrazePurchaseEvent(params.item.name, amount, {
    transactionId,
    product_id: params.item.id,
    currency: PURCHASE_ITEM.CURRENCY,
    ...params.item.params,
  });

  trackBrazeImtuPurchaseEvent(
    `${type}${PURCHASE_ITEM.NAME_IMTU.toLowerCase()}`,
    phone,
    country,
  );

  trackBrazeCustomAttribute(`${type}mtu_send_${country}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_${carrier}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_${plan}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_date`, String(new Date()));
  trackBrazeCustomAttribute(`${type}mtu_send_date_${country}`, String(new Date()));
};

export const trackSentCallingRechargeTransaction = (
  args: SentCallingRechargeTransactionParams,
) => {
  kochavaApi.trackCallingPurchase({ ...args });
  trackGaPurchasePinless({ userCountryCode: args.userCountryCode });
};

export const trackSentImtuTransaction = (params: ImtuTxnTrackingParams) => {
  const {
    amount,
    transactionId,
    paymentType,
    userCountryCode,
    purchaseParams: { type, country, phone, carrier, plan },
  } = params;

  const itemName = `${type}${PURCHASE_ITEM.NAME_IMTU}`;
  const itemId = `${itemName}/${carrier}/${country}/$${amount}/${plan}/${phone}`;
  const category =
    type === ImtuProductType.Domestic
      ? IMTU_PRODUCT_TYPES.DOMESTIC
      : IMTU_PRODUCT_TYPES.INTERNATIONAL;

  const analyticsItem = {
    item_id: itemId,
    item_name: itemName,
    item_category: `${category} Airtime Topup`,
    item_category2: carrier,
    item_category3: plan,
    item_category4: phone,
    item_variant: country,
    item_list_name: carrier,
  };

  trackGaPurchaseImtu({
    transactionId,
    item: analyticsItem,
    amount,
    userCountryCode,
    options: { paymentType },
  });

  kochavaApi.trackImtuPurchase({
    ...analyticsItem,
    transactionId,
    amount,
    paymentType,
  });

  const purchaseItemParams = {
    id: itemId,
    name: PURCHASE_ITEM.NAME_IMTU,
    params: params.purchaseParams,
  };
  trackImtuPurchaseToMarketingServices({
    transactionId,
    item: purchaseItemParams,
    amount,
  });
};

export const trackSentCallingPlanTransaction = (params: CallingTxnTrackingParams) => {
  const {
    userCountryCode,
    price,
    currency,
    transactionId,
    planId,
    shouldSubscribe,
    planTitle,
    planDescription,
  } = params;

  const analyticsItem = {
    userCountryCode,
    price,
    currency,
    transactionId,
    planId,
    shouldSubscribe,
    planTitle,
    planDescription,
  };

  firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.PurchasePlans, analyticsItem);
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    analyticsItem,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchasePlans,
    analyticsItem,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    analyticsItem,
  );
};

export const trackAction = (params: ActionParams) => {
  const eventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Action, eventParams);
  trackBrazeEvent(`${GoogleAnalyticsEvent.Action}_${eventParams.name}`, {
    context: eventParams.context,
  });
};

export const trackError = (params?: AnalyticsErrorEventParams) => {
  const eventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Error, eventParams);
};

export function trackBackendFailure(params: BackendFailureParams) {
  trackCustomEvent(GoogleAnalyticsEvent.BackendError, { ...params });
}

export const trackLogin = () => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.Login);
};

export const trackLogout = (userId: string) => {
  logoutBrazeUser(userId);
  // TODO: Intercom logout & register unidentified user
};

export const trackSignUp = (userId: string) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.SignUp);
  kochavaApi.trackSignUp(userId);
};
