import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Img } from 'react-image';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { Flag } from '@components/common';
import PaymentCardIcon from '@components/common/PaymentCardIcon';
import BrCard from '@components/common/BrCard';

import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import Status from './components/Status';

import './styles.scss';

interface Props {
  txnStatus: ImtuStatus;
  countryCode: CountryCode;
  recipientPhoneNumber: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  paymentInfo?: PaymentCardClass | null;
  promoInfo?: ImtuPromoOfferDetailsClass;
  txnErrorCode?: string;
}

const Receipt: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    txnStatus,
    carrier,
    product,
    paymentInfo,
    countryCode,
    recipientPhoneNumber,
    promoInfo,
    txnErrorCode,
  } = props;

  const { t } = useTranslation();

  const rowClassNames = 'text-body/callout/default space-y-small';
  const rowHeaderClassNames =
    'flex flex-col capitalize text-body/caption/default text-support-colors/highlights';
  const rowIconContainerClassNames = 'w-xlarge h-xlarge';
  const rowValueContainerClassNames = 'flex flex-row items-center space-x-middle';

  const divider = (
    <div className="border-b border-dashed border-support-colors/highlights" />
  );

  const isPositiveStatus = ['success', 'queued'].includes(txnStatus);

  return (
    <div className="mb-xxxlarge">
      <div className="flex flex-col items-center w-full">
        <Status txnStatus={txnStatus} txnErrorCode={txnErrorCode} />

        {isPositiveStatus && (
          <>
            <BrCard className="w-full space-y-middle">
              <div className={rowClassNames}>
                <div className={rowHeaderClassNames}>{t("recipient's country")}</div>
                <div className={rowValueContainerClassNames}>
                  <div
                    className={normalizeStringCompound([
                      rowIconContainerClassNames,
                      'flex rounded-full overflow-hidden mr-middle',
                    ])}
                  >
                    <Flag className="w-full" code={countryCode} />
                  </div>
                  {t(getCountryNameByCode(countryCode) || '')}
                </div>
              </div>
              <div className={rowClassNames}>
                <div className={rowHeaderClassNames}>{t('phone number to top up')}</div>
                <div>{recipientPhoneNumber}</div>
              </div>
              {carrier && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('carrier')}</div>
                  <div className={rowValueContainerClassNames}>
                    <div className={rowIconContainerClassNames}>
                      <Img src={carrier.logoUrl} />
                    </div>
                    <div>{carrier.name}</div>
                  </div>
                </div>
              )}
              {product && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('top-up plan')}</div>
                  <div>
                    {product.senderBaseAmountText}{' '}
                    {Boolean(product?.recipientAmount) &&
                      (!product.hasSameSenderRecipientAmount ? (
                        <span className="opacity-40  whitespace-nowrap">
                          {`(${product.recipientAmountText})`}
                        </span>
                      ) : null)}
                  </div>
                </div>
              )}
              {paymentInfo && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('payment info')}</div>
                  <div className={rowValueContainerClassNames}>
                    {paymentInfo.paySource && (
                      <div className={rowIconContainerClassNames}>
                        <PaymentCardIcon issuer={paymentInfo.paySource} />
                      </div>
                    )}
                    <div>{paymentInfo.maskedNumber}</div>
                  </div>
                </div>
              )}

              {(product?.fee !== undefined || promoInfo) && divider}
              <div className="space-y-small">
                {promoInfo && (
                  <div className="flex flex-col w-full text-left text-body/primary/demibold">
                    <div className="flex flex-row justify-between text-body/caption/default text-support-colors/highlights">
                      <div className="capitalize">{`${t('promo code')} (${
                        promoInfo.promoId
                      })`}</div>
                      <div className="flex-shrink-0 w-32 lg:w-30 text-right text-blue-400 whitespace-nowrap mt-auto">{`- ${promoInfo?.discountText}`}</div>
                    </div>
                  </div>
                )}

                {product?.fee !== undefined ? (
                  <div className="flex flex-col w-full text-left text-body/caption/default text-support-colors/highlights">
                    <div className="flex flex-row justify-between">
                      <div className="capitalize">{t('service fee')}</div>
                      <div className="text-body/caption/medium">{product?.feeText}</div>
                    </div>
                  </div>
                ) : null}
                {product?.discount !== undefined ? (
                  <div className="flex flex-col w-full text-left text-body/caption/default text-color/attention">
                    <div className="flex flex-row justify-between">
                      <div className="capitalize">{t('discount')}</div>
                      <div className="text-body/caption/medium">{`- ${product?.discountText}`}</div>
                    </div>
                  </div>
                ) : null}
                <div className="flex justify-between w-full text-body/primary/demibold capitalize">
                  <div>{t('your total cost')}</div>
                  <div>
                    {promoInfo?.priceTotalDiscountedText ||
                      product?.senderTotalAmountText}
                  </div>
                </div>
              </div>
            </BrCard>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(Receipt);
