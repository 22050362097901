import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImtuProductClass from '@root/models/ImtuProductClass';

import BrCard from '@components/common/BrCard';
import BrCardContent from '@components/common/BrCard/BrCardContent';

import { imtuProductSubTypeTextMap } from '@components/modules/Imtu/constants';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

interface Props {
  data: ImtuProductClass;
  isDetailsOpened?: boolean;
  onClick(id: string): void;
  onPromoBadgeClick?(value: string): void;
}

const FeaturedOfferCard: React.FC<Props> = (props) => {
  const { data, onClick, onPromoBadgeClick, isDetailsOpened } = props;

  const { t } = useTranslation();

  const handleProductSelect = () => {
    onClick(data.code);
  };

  const [isDetailsOpen, setIsDetailsOpen] = useState(isDetailsOpened);

  useEffect(() => {
    setIsDetailsOpen(isDetailsOpened);
  }, [isDetailsOpened]);

  const descriptionShort = data.benefitsCfg?.termAmountDays
    ? t('Valid for {{number}} days', { number: data.benefitsCfg?.termAmountDays })
    : undefined;

  const handleOnPromoBadgeClick = () => {
    setIsDetailsOpen((s) => !s);
    onPromoBadgeClick?.(data.code);
  };

  const handleOnDetailsBtnClick = () => {
    setIsDetailsOpen((s) => !s);
    onPromoBadgeClick?.(data.code);
  };

  const handleOnDetailsContentClick = () => {
    setIsDetailsOpen((s) => !s);
    onPromoBadgeClick?.(data.code);
  };

  return (
    <BrCard
      onClick={handleProductSelect}
      variant="new-featured"
      featuredText={t('FEATURED')}
      bgUrl={data.carrierPromo?.imageUrl}
      fallbackImageUrl={defaultCountryImage}
      badgeCfg={[
        {
          text: imtuProductSubTypeTextMap[data.subType],
          classType: 'neutral',
          styleType: 'default',
        },
        {
          text: data.carrierPromo?.title.toLocaleUpperCase(),
          classType: 'warning',
          iconLeft: 'egift-solid',
          onClick: handleOnPromoBadgeClick,
          className: 'h-full',
        },
      ]}
    >
      <BrCardContent
        id={data.code}
        planTypeText={data?.subType}
        title={data?.name}
        isDetailsOpen={isDetailsOpen}
        brAlertCfg={{
          icon: 'egift-solid',
          classType: 'warning',
          styleType: 'with-header',
          className: 'w-full',
          header: t('Promotion details'),
        }}
        brAlertContent={data.carrierPromo?.description}
        details={data?.description}
        descriptionShort={descriptionShort}
        actionText={`${t('Buy')} ${data.senderBaseAmountText}`}
        onActionBtnClick={handleProductSelect}
        onDetailsBtnClick={handleOnDetailsBtnClick}
        onDetailsContentClick={handleOnDetailsContentClick}
      />
    </BrCard>
  );
};

export default FeaturedOfferCard;
