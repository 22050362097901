import { makeAutoObservable } from 'mobx';

import { normalizeStringCompound } from '@utils/string';

import { UserProfileResponse } from '@root/interfaces/contract/UserAccount';
import { Languages } from '@root/interfaces/Languages';

import { getCountryBackgroundImgSrc } from '@services/cdn';
import { getCountryCodeByPhoneNumber } from '@helpers/phone';

export class UserAccountClass {
  id: string;

  firstName: string;

  lastName: string;

  avatarURL: string;

  phone: string;

  email: string;

  language?: Languages;

  color: string;

  dateOfBirth?: null | Date;

  get shortName() {
    return normalizeStringCompound([this.firstName, this.lastName]);
  }

  get fullName() {
    return this.shortName;
  }

  constructor(user: Partial<UserProfileResponse>) {
    makeAutoObservable(this);

    this.id = user.id || '';
    this.firstName = user.firstname ?? '';
    this.lastName = user.lastname ?? '';

    if (user.avatar_url) {
      this.avatarURL = user.avatar_url;
    } else {
      const countryCodeFromPhone = getCountryCodeByPhoneNumber(user.msisdn);
      this.avatarURL = getCountryBackgroundImgSrc(countryCodeFromPhone || 'US');
    }
    this.color = user.color || '';
    this.dateOfBirth = user.dob ? new Date(user.dob) : null;
    this.phone = user.msisdn || '';
    this.email = user.verified_email || user.unverified_email || '';

    if (user.language && Object.values(Languages).includes(user.language as Languages)) {
      this.language = user.language as Languages;
    }
  }

  setLanguage = (lng: Languages) => {
    this.language = lng;
  };
}

export default UserAccountClass;
