/* eslint-disable @typescript-eslint/indent */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';

import { normalizeStringCompound } from '@utils/string';

import useNavigation from '@root/hooks/useNavigation';
import useOnClickOutside from '@root/hooks/useOnClickOutside';

import delay from '@utils/delay';

import { OptionProps } from '@root/interfaces/components/Select';
import BrSelectProps, {
  BrOptionProps,
  isOptGroupProps,
} from '@root/interfaces/components/BrSelect';

import BrInput from '../BrInput';
import OptionList from './OptionList';

import Dropdown from './Dropdown';
import Drawer from './Drawer';

import { useIsDesktop } from '../MediaQueryMatchers';
import useDropdownOpenDirection from './useDropdownOpenDirection';
import useOptionsToRender from './useOptionsToRender';
import { animationComplete } from '../BrDrawer';
import BrNotFoundInfo from '../BrNotFoundInfo';

import './styles.scss';

export type BrSelectRef = {
  setSelectIsOpen(val: boolean): void;
  isOpen: boolean;
  drawerRef: React.RefObject<HTMLDivElement>;
  dropdownRef: React.RefObject<HTMLDivElement>;
};

const BR_INPUT_ICON_RIGHT_CLASS_NAME = '.br-input-icon-right';
const DELAY_BEFORE_DROPDOWN_WILL_BE_CLOSED_MS = 150;

const BrSelect = forwardRef<BrSelectRef, BrSelectProps>((props, ref) => {
  const {
    value,
    placeholder,
    drawerTitleText,
    options,
    selectButtonAddonBefore,
    dataTestId,
    notFoundText = 'Sorry, no matches found', // t('Sorry, no matches found')
    isOpened,
    isDisabled,
    isAllowUnselect = true,
    hasSearch,
    onChange,
    onClose,
    onFocus,
    onOpen,
    children,
    onSearch,
    renderItem,
    dropdownAddonBottom,
    drawerAddonBottom,
    dropdownMaxHeight,
    ignoredElemsRefs,
    onAnimationEnd,
    className,
    dropdownClassName,
    renderOverlay,
    onWhat,
    dropdownPaddingVariant,
    onClickOutside,
    ...rest
  } = props;

  const valueOption =
    typeof value === 'string'
      ? options.reduce((acc: BrOptionProps | undefined, item) => {
          if (isOptGroupProps(item)) {
            const optionOption = item.options.find((opt) => opt.value === value);
            return optionOption || acc;
          }
          if (item.value === value) {
            return item;
          }
          return acc;
        }, undefined)
      : value;

  const [currentOption, setCurrentOption] = useState(valueOption);
  const [isOpen, setIsOpen] = useState(false);
  const [isPrevOpened, setIsPrevOpened] = useState<boolean | undefined>(false);
  const [searchValue, setSearchValue] = useState('');

  const isClosing = useRef(false);

  const mobileInputRef = useRef<HTMLInputElement>(null);

  if (isPrevOpened !== isOpened) {
    setIsPrevOpened(isOpened);
    setIsOpen(Boolean(isOpened));
  }

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputIconRightRef = useRef<HTMLDivElement | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const isDesktopResolution = useIsDesktop();

  const optionsToRender = useOptionsToRender({
    options,
    searchValue,
    onSearch,
    isClosingRef: isClosing,
    currentOption,
    isOpen,
  });

  const handleClickOutside = async () => {
    if (!optionsToRender.length) {
      setSearchValue('');
    }
    setIsOpen(false);
    inputRef.current?.blur();
    await onClickOutside?.();
  };

  const ignoredElemRefs = [
    dropdownRef,
    drawerRef,
    inputRef,
    inputIconRightRef,
    mobileInputRef,
    ...(ignoredElemsRefs ?? []),
  ];

  useOnClickOutside(handleClickOutside, ...ignoredElemRefs);

  useEffect(() => {
    const iconRight = document.querySelector<HTMLDivElement>(
      BR_INPUT_ICON_RIGHT_CLASS_NAME,
    );
    inputIconRightRef.current = iconRight;
  }, []);

  const getExposedSelectApi = () => ({
    setSelectIsOpen(val: boolean) {
      setIsOpen(val);
    },
    isOpen,
    drawerRef,
    dropdownRef,
  });

  useImperativeHandle(ref, getExposedSelectApi);

  // update selected value when the value or the options have changed
  useEffect(() => {
    setCurrentOption(valueOption);
  }, [value, options, valueOption]);

  useLayoutEffect(() => {
    if (isOpen && !isDesktopResolution) {
      inputRef.current?.blur();
    }
  }, [isOpen, isDesktopResolution]);

  useEffect(() => {
    if (isOpen && isDesktopResolution) {
      inputRef.current?.focus();
    }
  }, [isDesktopResolution, isOpen]);

  useEffect(() => {
    if (isDesktopResolution) {
      return undefined;
    }
    if (isOpen) {
      document.documentElement.classList.add('overflow-hidden');
    } else {
      document.documentElement.classList.remove('overflow-hidden');
    }
    return () => {
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, [isOpen, isDesktopResolution]);

  const handleSelectFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFocus?.(e);
  };

  const handleSelectClick = async () => {
    setIsOpen((s) => !s);
    if (isDesktopResolution) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  };

  const handleOptionSelect = async (option: OptionProps) => {
    const isSelectedOption = option.value === currentOption?.value;
    if (isSelectedOption && !isAllowUnselect) {
      return undefined;
    }
    setCurrentOption(isSelectedOption && isAllowUnselect ? undefined : option);
    await delay(DELAY_BEFORE_DROPDOWN_WILL_BE_CLOSED_MS);
    isClosing.current = true;
    setIsOpen(false);
    if (dropdownRef.current) {
      await animationComplete(dropdownRef.current);
    }
    onChange?.(option?.value);
    return undefined;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const inputClassNames = normalizeStringCompound([
    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
    'focus-within:border-blue-400 truncate relative z-20',
  ]);

  useNavigation(dropdownRef, 'button.block', [optionsToRender]);

  const dropdownOpenDirection = useDropdownOpenDirection({
    isOpen,
    inputWrapper: inputWrapperRef?.current,
    dropdown: dropdownRef?.current,
  });

  const handleInputRef = (compRef: HTMLInputElement | null) => {
    if (compRef) {
      inputRef.current = compRef;
    }
  };

  const handleOnClearIconClick = () => {
    if (searchValue) {
      setSearchValue('');
    }
  };

  const handleAnimationEnd = () => {
    if (isOpen) {
      onOpen?.();
    } else {
      isClosing.current = false;
      onClose?.();
      setSearchValue('');
    }
    if (!isDesktopResolution) {
      inputRef.current?.blur();
    }
    onAnimationEnd?.();
  };

  const mobileSearchInput = (
    <BrInput
      onChange={hasSearch ? handleSearch : undefined}
      placeholder={placeholder}
      value={searchValue}
      autoComplete="nope"
      onWhat="surface"
      ref={mobileInputRef}
      iconRight={searchValue ? 'CloseXSolid' : undefined}
      onIconClickRight={handleOnClearIconClick}
    />
  );

  const brSelectClassNames = normalizeStringCompound([
    'br-select',
    isOpen ? 'br-select-opened' : '',
    !searchValue || !isOpen ? 'br-select_chevron-icon' : 'br-select_erase-icon',
    className,
  ]);

  const handleBrDrawerRef = (
    brDrawerRef: {
      drawerRef: React.RefObject<HTMLDivElement>;
    } | null,
  ) => {
    if (brDrawerRef) {
      drawerRef.current = brDrawerRef.drawerRef.current;
    }
  };

  const handleBrDrawerOnClose = () => {
    setIsOpen(false);
  };

  const renderCustomListItem = renderItem
    ? (
        item: {
          option: OptionProps;
          isActive: boolean;
          onSelect: (options: OptionProps) => void;
        },
        index: number,
      ) => {
        return renderItem(item, index);
      }
    : undefined;

  const dropdownStyles =
    dropdownOpenDirection === 'up' && inputWrapperRef?.current
      ? {
          top: -inputWrapperRef.current.clientHeight,
        }
      : undefined;

  const optionsToRenderInDefaultList = optionsToRender.reduce((acc, item) => {
    return isOptGroupProps(item) ? [...acc, ...item.options] : [...acc, item];
  }, [] as BrOptionProps[]);

  const overlay = renderOverlay ? (
    renderOverlay({
      options: optionsToRender,
      searchValue,
      selectedOption: currentOption,
      onItemSelect: (opt) => handleOptionSelect(opt),
    })
  ) : (
    // TODO: add support of OptGroups in default OptionList component
    <OptionList
      options={optionsToRenderInDefaultList}
      renderItem={renderCustomListItem}
      onSelect={handleOptionSelect}
      selectedOption={currentOption}
    />
  );

  const overlayContent = (
    <>
      {overlay}
      {!optionsToRender.length && (
        <div className="w-full h-full flex flex-col items-center justify-center mb-default">
          <BrNotFoundInfo illustrationType="notFound" text={notFoundText} />
        </div>
      )}
    </>
  );

  return (
    <div ref={inputWrapperRef} className={brSelectClassNames}>
      {typeof children === 'function' ? (
        children({
          isOpened: isOpen,
          onClick: handleSelectClick,
          searchValue,
          onSearch: handleSearch,
        })
      ) : (
        <BrInput
          addonBefore={selectButtonAddonBefore}
          isReadOnly={!hasSearch || !isDesktopResolution}
          value={isOpen && hasSearch ? searchValue : currentOption?.text || ''}
          className={inputClassNames}
          isDisabled={isDisabled}
          onFocus={handleSelectFocus}
          onClick={handleSelectClick}
          onChange={hasSearch ? handleSearch : undefined}
          ref={handleInputRef}
          dataTestId={`${dataTestId}-input`}
          autoComplete="nope"
          placeholder={placeholder}
          iconRight={searchValue && isOpen ? 'CloseXSolid' : 'chevron-down-outline'}
          iconRightTabIndex={searchValue && isOpen ? 0 : -1}
          onIconClickRight={handleOnClearIconClick}
          onWhat={onWhat}
          {...rest}
        />
      )}
      {isDesktopResolution ? (
        <Dropdown
          addonBottom={dropdownAddonBottom}
          ref={dropdownRef}
          isOpen={isOpen}
          onAnimationEnd={handleAnimationEnd}
          dropdownMaxHeight={dropdownMaxHeight}
          className={dropdownClassName}
          dropdownOpenDirection={dropdownOpenDirection}
          style={dropdownStyles}
          overlay={overlayContent}
          paddingVariant={dropdownPaddingVariant}
        />
      ) : (
        <Drawer
          title={drawerTitleText}
          selectedOption={currentOption}
          searchValue={searchValue}
          renderMobileSearchInput={
            hasSearch && <div className="mb-small">{mobileSearchInput}</div>
          }
          isOpen={!isDesktopResolution && isOpen}
          ref={handleBrDrawerRef}
          onClose={handleBrDrawerOnClose}
          onAnimationEnd={handleAnimationEnd}
          addonBottom={drawerAddonBottom}
          overlay={overlayContent}
        />
      )}
    </div>
  );
});

export default BrSelect;
