import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikHelpers } from 'formik';
import Yup from '@utils/validation/yup';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { FormConfig } from '@root/interfaces/components/Form';
import { BrAdvancedInputProps } from '@root/interfaces/components/BrAdvancedInput';

import BrTooltipWrapper from '../BrTooltip/BrTooltipWrapper';
import BrAdvancedInput, { BrAdvancedInputRef } from '../BrAdvancedInput';
import BrButton from '../BrButton';

interface Props {
  isLoading?: boolean;
  onSubmit(promoCode: string): Promise<{ isPromoApplied: boolean }>;
}

interface PromoCodeFormValues {
  promoCode: string;
}

type ErrorReason = 'empty' | 'invalid-code';
type IconCfg = Pick<BrAdvancedInputProps, 'iconRight' | 'iconRightClassNames'>;

const MIN_LENGTH_OF_PROMO_CODE = 1;
const EMPTY_ERROR_MSG = '';

const TOOLTIP_ERROR_MSG_MAP = {
  EMPTY_CODE: 'Please provide a top up promo code', // t('Please provide a top up promo code')
  INVALID_CODE: 'Please provide a valid top up promo code', // t('Please provide a valid top up promo code')
};

const INPUT_ERROR_MSG_MAP = {
  EMPTY_CODE: EMPTY_ERROR_MSG,
  INVALID_CODE: 'Invalid top up code', // t('Invalid top up code')
};

const ICON_CLEAR_CFG: IconCfg = {
  iconRight: 'close_-_x_-_times_fill',
  iconRightClassNames: 'text-support-colors/highlights',
};

const getApplyPromoBtnTootipText = (errorReason?: ErrorReason) => {
  switch (errorReason) {
    case 'empty':
      return TOOLTIP_ERROR_MSG_MAP.EMPTY_CODE;
    case 'invalid-code':
      return TOOLTIP_ERROR_MSG_MAP.INVALID_CODE;
    default:
      return undefined;
  }
};

const BrPromoCodeForm = (props: Props) => {
  const { onSubmit, isLoading } = props;

  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);

  const brAdvancedInputRef = useRef<BrAdvancedInputRef>(null);

  useOnClickOutside(() => {
    setIsFocused(false);
  }, brAdvancedInputRef.current?.inputWrapperRef || { current: undefined });

  const handleFormSubmit = async (
    values: PromoCodeFormValues,
    { setErrors }: FormikHelpers<PromoCodeFormValues>,
  ) => {
    const { isPromoApplied } = await onSubmit(values.promoCode);
    if (!isPromoApplied) {
      setErrors({ promoCode: INPUT_ERROR_MSG_MAP.INVALID_CODE });
    }
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const formInitialProps: FormConfig<PromoCodeFormValues> = {
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      promoCode: Yup.string()
        .min(MIN_LENGTH_OF_PROMO_CODE, INPUT_ERROR_MSG_MAP.EMPTY_CODE)
        .required(INPUT_ERROR_MSG_MAP.EMPTY_CODE),
    }),
    onSubmit: handleFormSubmit,
    initialValues: { promoCode: '' },
    validateOnMount: true,
  };

  return (
    <Formik {...formInitialProps}>
      {(formik) => {
        const handleOnIconClickRight = () => {
          formik.setFieldValue('promoCode', '');
          brAdvancedInputRef.current?.inputRef.current?.focus();
        };

        const errorReason = (() => {
          switch (formik.errors.promoCode) {
            case EMPTY_ERROR_MSG:
              return 'empty';
            case 'Invalid top up code':
              return 'invalid-code';
            default:
              return undefined;
          }
        })();

        const inputBottomLabel =
          !formik.errors.promoCode &&
          t('Promo will only be applied to current transaction');

        const hasError = Boolean(
          formik.getFieldMeta('promoCode').error &&
            formik.getFieldMeta('promoCode').touched,
        );

        const isApplyBtnDisabled = !formik.isValid || isLoading;

        return (
          <Form>
            <BrAdvancedInput
              id="promoCode"
              ref={brAdvancedInputRef}
              onWhat="surface"
              placeholder={t('Enter top up promo code')}
              topLabel={t('Top up promo code')}
              bottomLabel={inputBottomLabel}
              isDisabled={isLoading}
              hasError={hasError}
              errorText={formik.getFieldMeta('promoCode').error}
              onFocus={handleOnFocus}
              onIconClickRight={handleOnIconClickRight}
              {...(formik.values.promoCode && isFocused ? ICON_CLEAR_CFG : undefined)}
              {...formik.getFieldProps('promoCode')}
            />
            <BrTooltipWrapper
              cfg={{
                place: 'top',
                content: getApplyPromoBtnTootipText(errorReason),
              }}
            >
              <BrButton
                type="submit"
                disabled={isApplyBtnDisabled}
                text={isLoading ? t('Checking...') : t('Apply')}
                className="w-full mt-default"
              />
            </BrTooltipWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrPromoCodeForm;
