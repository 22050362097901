import React from 'react';

import { normalizeStringCompound } from '@utils/string';

type ExtraField = {
  title: string;
  valueText: string;
  type?: 'discount';
};

interface Props {
  data: ExtraField[];
  className?: string;
}

const ExtraFieldsList: React.FunctionComponent<Props> = (props: Props) => {
  const { data, className } = props;

  const wrapperClassNames = normalizeStringCompound([className, 'space-y-small']);

  return (
    <div className={wrapperClassNames}>
      {data.map((field) => {
        const rowClassNames = normalizeStringCompound([
          'flex items-center justify-between pr-xxlarge text-body/callout/default',
          field.type === 'discount'
            ? 'text-color/attention'
            : 'text-support-colors/highlights',
        ]);
        return (
          <div key={`${field.title}-${field.valueText}`} className={rowClassNames}>
            <div>{field.title}</div>
            <div>{field.valueText}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ExtraFieldsList;
