import { OnClickProps } from '@components/common/BrContacts';
import { ContactActivity } from '@components/common/BrListItem';
import BrCard from '@components/common/BrCard';

import { formatMoneyToText } from '@helpers/money';

import CountryCode from '@root/interfaces/CountryCode';

import { getCountryBackgroundImgSrc } from '@services/cdn';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

interface Props {
  phone: string;
  senderAmount?: number;
  senderCurrency: string;
  carrierName: string;
  recipientAmount: number;
  recipientCountryCode: CountryCode;
  promoText?: string;
  recipientCurrency: string;
  onClickProps?: OnClickProps;
  hasSeparateLine?: boolean;
  additionalText?: string;
}

const RecentTxn: React.FC<Props> = (props) => {
  const {
    phone,
    promoText,
    senderAmount,
    senderCurrency,
    recipientAmount,
    recipientCurrency,
    carrierName,
    additionalText,
    recipientCountryCode,
    onClickProps,
    hasSeparateLine,
  } = props;

  const bottomText = `${carrierName} ${recipientCountryCode} · ${formatMoneyToText(
    recipientAmount,
    recipientCurrency,
  )}`;

  return (
    // !overflow-visible is to prevent BrCard to cut the badge
    <BrCard
      variant="without-paddings"
      className="bg-color/surface !overflow-visible py-0"
    >
      <ContactActivity
        imageUrl={getCountryBackgroundImgSrc(recipientCountryCode)}
        imageFallbackUrl={defaultCountryImage}
        topText={phone}
        priceText={
          senderAmount ? formatMoneyToText(senderAmount, senderCurrency) : undefined
        }
        badgeText={promoText}
        denomText={bottomText}
        hasSeparateLine={hasSeparateLine}
        onClickProps={onClickProps}
        additionalText={additionalText}
      />
    </BrCard>
  );
};

export default RecentTxn;
