import { makeAutoObservable } from 'mobx';

import { fetchAutoPromoInfo, fetchPromoInfo } from '@services/api/v1/dtcImtuK2';

import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import {
  FetchAutoPromoInfoServiceReqData,
  FetchPromoInfoServiceReqData,
} from '@services/api/v1/dtcImtuK2/helpers';

class SendImtuTransactionStore {
  isLoading = false;

  promoInfo?: ImtuPromoOfferDetailsClass;

  autoPromoInfo?: ImtuPromoOfferDetailsClass;

  constructor() {
    makeAutoObservable(this);
  }

  getAutoPromoInfo = async (params: FetchAutoPromoInfoServiceReqData) => {
    this.setIsLoading(true);
    try {
      const promoInfo = await fetchAutoPromoInfo(params);
      this.autoPromoInfo = promoInfo;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('No automatic promo available');
    } finally {
      this.setIsLoading(false);
    }
  };

  getPromoInfo = async (params: FetchPromoInfoServiceReqData) => {
    this.setIsLoading(true);
    try {
      const promoInfo = await fetchPromoInfo(params);
      this.promoInfo = promoInfo;
    } finally {
      this.setIsLoading(false);
    }
  };

  reset() {
    this.promoInfo = undefined;
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };
}

export default SendImtuTransactionStore;
