import { overwrite, getName } from 'country-list';

import CountryCode from '@root/interfaces/CountryCode';

import { logError } from '@services/logging';

overwrite([
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
]);

// eslint-disable-next-line import/prefer-default-export
export const getCountryNameByCode = (countryCode?: CountryCode) => {
  if (countryCode) {
    try {
      return getName(countryCode) || countryCode;
    } catch (err) {
      logError({
        error: Error(
          `getCountryNameByCode error -> countryCode ${countryCode} is unknown. Update country-list library and libphonenumber`,
        ),
        errorInfo: err,
      });
      return countryCode;
    }
  }

  return undefined;
};
