import Axios from './Axios';
import DtcApi from './DtcApi';
import CallingApi from './CallingApi';
import DtcImtuK2Api from './DtcImtuK2Api';
import DtcCallingPlansApi from './DtcCallingPlansApi';

export const { instance: dtcbAuthAxios } = new DtcApi({
  baseURL: process.env.REACT_APP_DTCB_AUTH_BASE_URL,
});

export const { instance: dtcbProfileAxios } = new DtcApi({
  baseURL: process.env.REACT_APP_DTCB_PROFILE_BASE_URL,
});

export const { instance: callingBAxios } = new CallingApi({
  baseURL: process.env.REACT_APP_CALLINGB_BASE_URL,
  withCredentials: false,
});

export const { instance: dtcCallingPlansApi } = new DtcCallingPlansApi({
  baseURL: process.env.REACT_APP_DTC_BASE_URL,
  withCredentials: false,
});

export const { instance: dtcImtuK2Axios } = new DtcImtuK2Api({
  baseURL: process.env.REACT_APP_DTC_IMTU_K2_BASE_URL,
  withCredentials: false,
});

export const { instance: dtcLoggingAxios } = new Axios({
  baseURL: process.env.REACT_APP_DTCB_LOGGING_BASE_URL,
  withCredentials: false,
});

export const { instance: netlifyLoggingAxios } = new Axios({
  withCredentials: false,
});
