import { AxiosError } from 'axios';

import { dtcLoggingAxios, netlifyLoggingAxios } from '@services/axios';

import { DTC_LOGGING_ENDPOINTS } from '@services/api/v1/constants/dtc';
import { NETLIFY_ENDPOINTS } from '@services/api/v1/constants/netlify';

import UserAccountClass from '@root/models/UserAccountClass';
import SessionClass from '@root/models/SessionClass';

const cleanupLogParameter = (parameter: unknown): string => {
  const jsonStringRes = JSON.stringify(parameter);
  // JSON.stringify === undefined but typescript says it is always string
  if (jsonStringRes) {
    const newLineRegexp = /\\n|\\/g;
    const extraSpaceRegexp = /\s+/g;
    const res = jsonStringRes
      .replace(newLineRegexp, '')
      .replace(extraSpaceRegexp, ' ')
      .trim();
    return res;
  }

  return 'undefined';
};

const extractRequestInfo = (error: Error | AxiosError) => {
  if ('config' in error) {
    return {
      url: error?.config?.url,
      data: error?.config?.data,
    };
  }

  return undefined;
};

type LogErrorParams = {
  error: Error | AxiosError;
  errorInfo: unknown;
  userInfo?: UserAccountClass | SessionClass | null;
};

const getUserIdFromUserInfo = (userInfo: LogErrorParams['userInfo']) => {
  if (userInfo) {
    if ('id' in userInfo) {
      return userInfo.id;
    }

    if ('profileId' in userInfo) {
      return userInfo.profileId;
    }
  }

  return undefined;
};

export const logErrorGraylog = (params: LogErrorParams) => {
  try {
    const userId = getUserIdFromUserInfo(params.userInfo);
    const requestInfo = extractRequestInfo(params.error);

    dtcLoggingAxios.post(DTC_LOGGING_ENDPOINTS.HTTP_GELF, {
      userId,
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${params.error?.name} ${params.error?.message}`,
      level: 3,
      _error_stack: cleanupLogParameter(params.error?.stack),
      _error_info: cleanupLogParameter(params.errorInfo),
      _request_info: cleanupLogParameter(requestInfo),
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', params);
  }
};

export const logErrorNetlify = (params: LogErrorParams) => {
  try {
    const userId = getUserIdFromUserInfo(params.userInfo);
    const requestInfo = extractRequestInfo(params.error);

    netlifyLoggingAxios.post(NETLIFY_ENDPOINTS.LOG_ERROR, {
      userId,
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${params.error?.name} ${params.error?.message}`,
      _error_stack: cleanupLogParameter(params.error?.stack),
      _request_info: cleanupLogParameter(requestInfo),
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', params);
  }
};

export const logError = (params: LogErrorParams) => {
  logErrorGraylog(params);
  logErrorNetlify(params);
};

export const logMessageGraylog = (message: string) => {
  try {
    dtcLoggingAxios.post(DTC_LOGGING_ENDPOINTS.HTTP_GELF, {
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${message}`,
      level: 3,
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', message);
  }
};

export const logMessageNetlify = (message: string) => {
  try {
    netlifyLoggingAxios.post(NETLIFY_ENDPOINTS.LOG_INFO, {
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${message}`,
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', message);
  }
};

export const logMessage = (message: string) => {
  logMessageGraylog(message);
  logMessageNetlify(message);
};
