import React from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import BrBadge from '@components/common/BrBadge';
import BrContacts from '@components/common/BrContacts';
import BrButton from '@components/common/BrButton';

interface Props {
  name?: string;
  imageUrl?: string;
  countryCode?: CountryCode;
  number?: string;
  badgeText?: string;
  onClick?(): void;
}

const RecipientInfo: React.FC<Props> = (props) => {
  const { imageUrl, countryCode, name, number, badgeText, onClick } = props;

  const { t } = useTranslation();

  return (
    <div className="flex flex-row space-x-middle">
      {/* !min-w-[64px] because in Figma they reused BrContacts component but override it's min-width */}
      {imageUrl ? (
        <BrContacts cmpType="person" imageUrl={imageUrl} className="!min-w-[64px]" />
      ) : null}
      {countryCode ? (
        <BrContacts cmpType="flag" countryCode={countryCode} className="!min-w-[64px]" />
      ) : null}
      <div className="flex w-full flex-row items-center justify-between space-x-xsmall">
        <div className="space-y-xsmall">
          {badgeText ? <BrBadge classType="success-variant" text={badgeText} /> : null}
          {name ? (
            <div className="text-body/primary/demibold text-on-colors/on-primary">
              {name}
            </div>
          ) : null}
          {number ? (
            <div className="text-body/footnote/default text-on-colors/on-primary">
              {number}
            </div>
          ) : null}
        </div>
        {onClick && (
          <div className="justify-between">
            <BrButton cmpType="link" text={t('Edit')} onClick={onClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipientInfo;
