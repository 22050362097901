import { makeAutoObservable } from 'mobx';
import ImtuProductClass from '@root/models/ImtuProductClass';

class Store {
  product?: ImtuProductClass;

  prevProduct?: ImtuProductClass;

  isFeaturedPlanRefused?: boolean;

  constructor({ product }: { product?: ImtuProductClass }) {
    makeAutoObservable(this);

    this.product = product;
  }

  setProduct(product: ImtuProductClass) {
    this.product = product;
  }

  setPrevProduct(product: ImtuProductClass) {
    this.prevProduct = product;
  }

  setIsFeaturedPlanRefused(value: boolean) {
    this.isFeaturedPlanRefused = value;
  }
}

export default Store;
